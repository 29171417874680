import React from "react"
import { RenderLeafProps } from "slate-react"

const Quote = ({ children, attributes }: RenderLeafProps) => {
    return (
        <span
            {...attributes}
            data-before=""
            className="relative before:absolute before:-left-[25px] before:top-[7px] before:-z-1 before:font-['lavita-icon-font'] before:text-3xl before:font-normal before:leading-none before:text-border-copperfield before:content-[attr(data-before)] before:sm:-left-[30px] before:sm:top-[5px] before:sm:text-4xl before:lg:-left-[50px] before:lg:top-[10px] before:lg:text-6xl"
        >
            {children}
        </span>
    )
}

export default Quote
