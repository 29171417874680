import React from "react"
import { RenderElementProps } from "slate-react"

const SEOH1 = ({ children, attributes }: RenderElementProps) => {
    return (
        <h1
            {...attributes}
            className="mb-4 text-md last:mb-0 sm:mb-5 sm:text-lg lg:text-xl font-semibold"
        >
            {children}
        </h1>
    )
}

export default SEOH1
