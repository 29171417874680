import { A, Link } from "../../shared/utils/router"

import ConditionalWrapper from "./conditional-wrapper"
import Icon from "../universal/icon"
import IconActionStyles from "./icon-action.module.css"
import IconText from "./icon-text"
import React from "react"
import ReactMarkdown from "react-markdown"
import classNames from "classnames"
import rehypeRaw from "rehype-raw"
import rehypeSanitize from "rehype-sanitize"

const IconAction = props => (
    <div
        className={classNames(IconActionStyles.block, {
            [IconActionStyles.dark]: props.dark,
            [IconActionStyles.light]: props.light,
            [IconActionStyles.white]: props.white,
            [IconActionStyles.blue]: props.blue,
            [IconActionStyles.lightBlue]: props.lightBlue,
            [IconActionStyles.button]: props.button,
            [IconActionStyles.center]: props.center,
            [IconActionStyles.centerMobile]: props.centerMobile,
            [IconActionStyles.rotateIcon90]: props.rotateIcon90,
            [IconActionStyles.rotateIcon180]: props.rotateIcon180,
            [IconActionStyles.bigIcon]: props.bigIcon,
            [IconActionStyles.noMargin]: props.noMargin,
            [IconActionStyles.absolutePosition]: props.absolutePosition,
            [IconActionStyles.insideBlock]: props.insideBlock,
            [IconActionStyles.whiteDesktop]: props.whiteDesktop,
            [IconActionStyles.whiteTablet]: props.whiteTablet,
            [IconActionStyles.lessMargin]: props.lessMargin,
            [IconActionStyles.underline]: props.underline,
            [IconActionStyles.small]: props.small,
            [IconActionStyles.noMarginLeft]: props.noMarginLeft,
            [IconActionStyles.lessTopMargin]: props.lessTopMargin,
            [IconActionStyles.textLeft]: props.textLeft,
            [IconActionStyles.morePadding]: props.morePadding,
        })}
    >
        <ConditionalWrapper
            condition={props.url}
            wrapper={children => (
                <A href={props.url} onClick={props.trackGA}>
                    {children}
                </A>
            )}
        >
            <ConditionalWrapper
                condition={props.path}
                wrapper={children => (
                    <Link to={props.path} onClick={props.trackGA}>
                        {children}
                    </Link>
                )}
            >
                <ConditionalWrapper
                    condition={props.action || props.submit}
                    wrapper={children => (
                        <button
                            type={props.submit ? "submit" : "button"}
                            onClick={props.submit ? undefined : props.action}
                        >
                            {children}
                        </button>
                    )}
                >
                    {props.markdown ? (
                        <>
                            <Icon icon={props.icon} />
                            <ReactMarkdown rehypePlugins={[rehypeRaw, rehypeSanitize]}>{props.text}</ReactMarkdown>
                        </>
                    ) : (
                        <IconText icon={props.icon} text={props.text} />
                    )}
                </ConditionalWrapper>
            </ConditionalWrapper>
        </ConditionalWrapper>
    </div>
)

export default React.memo(IconAction)
