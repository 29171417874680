import React, { ReactNode } from "react"

interface ContainerProps {
    children: ReactNode
}

const Container = ({ children }: ContainerProps) => {
    return <div className="mx-auto box-border w-full px-6.5 md:px-10 lg:max-w-[1560px] lg:px-15">{children}</div>
}

export default Container
