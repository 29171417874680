import React, { useState } from "react"
import Typography, { TypographyElement } from "../Typography"

import { Color } from "../components/typography"
import { Icon } from "../components/icon"
import { Icons } from "../../types"
import classNames from "classnames"
import { types } from "react-bricks/frontend"

interface FAQProps {
    textVisible: TypographyElement[]
    textHidden: TypographyElement[]
    index?: number
}

const FAQ: types.Brick<FAQProps> = ({ index }) => {
    const [isCollapsed, setIsCollapsed] = useState(true)

    const toggleCollapsed = () => {
        setIsCollapsed(isCollapsed => !isCollapsed)
    }

    return (
        <div key={index}>
            <button
                type="button"
                onClick={toggleCollapsed}
                className={classNames(
                    "flex w-full gap-x-2 rounded-lg border border-solid border-background-tide p-5 text-left sm:items-center sm:gap-x-3 sm:p-8",
                    isCollapsed ? "mb-3.5 bg-white lg:mb-5" : "bg-background-tide"
                )}
            >
                <Icon
                    icon={Icons.link}
                    className={classNames("text-md text-dark-grey transition ease-in-out lg:text-lg", {
                        "rotate-90": !isCollapsed,
                    })}
                />
                <Typography block="copytext-small" allowList={[]} propName="textVisible" />
            </button>
            <div
                className={classNames(
                    "grid transition-grid-template-rows duration-500 ease-out",
                    isCollapsed ? "grid-rows-0fr" : "grid-rows-1fr"
                )}
            >
                <div className="overflow-hidden">
                    <div className="mb-7 mt-5 px-4 sm:mb-10 sm:mt-7 sm:px-15 lg:mb-16 lg:mt-10 lg:px-16">
                        <Color>
                            <Typography
                                block="copytext-small"
                                allowList={["bold", "sup", "link", "small"]}
                                propName="textHidden"
                            />
                        </Color>
                    </div>
                </div>
            </div>
        </div>
    )
}

FAQ.schema = {
    name: "FAQ",
    label: "FAQ",
    hideFromAddMenu: true,
    getDefaultProps: (): FAQProps => ({
        textVisible: [
            {
                type: "paragraph",
                children: [
                    {
                        text: "Lorem ipsum dolor sit amet, consetetur sadipscing elitr",
                    },
                ],
            },
        ],
        textHidden: [
            {
                type: "paragraph",
                children: [
                    {
                        text: "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua.",
                    },
                ],
            },
        ],
    }),
}

export default FAQ
