import { bagSvg, giftBoxSvg, heartMailv2Svg, mailSvg, pdfSvg } from "../../styles/svg"

import React from "react"

const icons = {
    mail: mailSvg,
    pdf: pdfSvg,
    bag: bagSvg,
    giftbox: giftBoxSvg,
    heartmail: heartMailv2Svg,
}

const IconText = ({icon, text, backgroundColor}) => (
    <>
        {icon && (icons[icon] ? icons[icon] : <i className="lavita-icon" style={{backgroundColor: backgroundColor}}>{icon}</i>)}
        <span>{text}</span>
    </>
)

export default React.memo(IconText)