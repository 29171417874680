const menu = [
    {
        translations: [
            {
                language: "de",
                title: "Darum LaVita",
                url: "https://www.lavita.de/mikronaehrstoffkonzentrat/warum-lavita",
            },
            {
                language: "en",
                title: "Why we need LaVita",
                url: "https://www.lavita.de/micronutrient-concentrate/why-lavita",
            },
            {
                language: "sv",
                title: "Varför vi behöver LaVita",
                url: "https://www.lavita.de/koncentration-av-mikronaringsamnen/varfoer-lavita",
            },
            {
                language: "fr",
                title: "Pourquoi nous avons besoin de LaVita",
                url: "https://www.lavita.de/concentration-en-micronutriments/pourquoi-lavita",
            },
            {
                language: "it",
                title: "Perché abbiamo bisogno di LaVita",
                url: "https://www.lavita.de/micronutrienti-concentrati/perche-lavita",
            },
            {
                language: "nl",
                title: "Waarom wij LaVita nodig hebben",
                url: "https://www.lavita.de/micronutrient-concentraat/waarom-lavita",
            },
            {
                language: "es",
                title: "Por qué necesitamos LaVita",
                url: "https://www.lavita.de/micronutrientes-concentrados/por-que-lavita",
            },
            {
                language: "no",
                title: "Hvorfor vi trenger LaVita",
                url: "https://www.lavita.de/mikronaeringsstoff-konsentrasjon/hvorfor-lavita",
            },
            {
                language: "da",
                title: "Derfor har vi brug for LaVita",
                url: "https://www.lavita.de/mikronaeringsstof-koncentration/hvorfor-lavita",
            },
            {
                language: "tr",
                title: "Neden LaVita",
                url: "https://www.lavita.de/mikro-besin-konsantresi/neden-lavita",
            },
            {
                language: "cs",
                title: "Proč LaVitu",
                url: "https://www.lavita.de/mikronutricni-koncentrat/proc-lavitu",
            },
        ],
        id: "darum_lavita",
    },
    {
        translations: [
            {
                language: "de",
                title: "Wirkungen",
                url: "https://www.lavita.de/mikronaehrstoffkonzentrat/wirkung",
            },
            {
                language: "en",
                title: "Effects",
                url: "https://www.lavita.de/micronutrient-concentrate/effects",
            },
            {
                language: "sv",
                title: "Effekter",
                url: "https://www.lavita.de/koncentration-av-mikronaringsamnen/effekter",
            },
            {
                language: "fr",
                title: "Effets",
                url: "https://www.lavita.de/concentration-en-micronutriments/effets",
            },
            {
                language: "it",
                title: "Effetti",
                url: "https://www.lavita.de/micronutrienti-concentrati/effetti",
            },
            {
                language: "nl",
                title: "Effecten",
                url: "https://www.lavita.de/micronutrient-concentraat/effecten",
            },
            {
                language: "es",
                title: "Efectos",
                url: "https://www.lavita.de/micronutrientes-concentrados/efectos",
            },
            {
                language: "no",
                title: "Virkninger",
                url: "https://www.lavita.de/mikronaeringsstoff-konsentrasjon/effekter",
            },
            {
                language: "da",
                title: "Virkninger",
                url: "https://www.lavita.de/mikronaeringsstof-koncentration/effekter",
            },
            {
                language: "tr",
                title: "Etkileri",
                url: "https://www.lavita.de/mikro-besin-konsantresi/etkileri",
            },
            {
                language: "cs",
                title: "Účinky",
                url: "https://www.lavita.de/mikronutricni-koncentrat/ucinky",
            },
        ],
        id: "wirkungen",
    },
    {
        translations: [
            {
                language: "de",
                title: "Das steckt drin",
                url: "https://www.lavita.de/mikronaehrstoffkonzentrat/zutaten",
            },
            {
                language: "en",
                title: "What it contains",
                url: "https://www.lavita.de/micronutrient-concentrate/ingredients",
            },
            {
                language: "sv",
                title: "Vad det innehåller",
                url: "https://www.lavita.de/koncentration-av-mikronaringsamnen/ingredienser",
            },
            {
                language: "fr",
                title: "Contenu",
                url: "https://www.lavita.de/concentration-en-micronutriments/ingredients",
            },
            {
                language: "it",
                title: "Cosa contiene",
                url: "https://www.lavita.de/micronutrienti-concentrati/ingredienti",
            },
            {
                language: "nl",
                title: "Dit zit erin",
                url: "https://www.lavita.de/micronutrient-concentraat/ingredienten",
            },
            {
                language: "es",
                title: "Lo que contiene",
                url: "https://www.lavita.de/micronutrientes-concentrados/ingredientes",
            },
            {
                language: "no",
                title: "Hva den inneholder",
                url: "https://www.lavita.de/mikronaeringsstoff-konsentrasjon/ingredienser",
            },
            {
                language: "da",
                title: "Hvad indeholder det",
                url: "https://www.lavita.de/mikronaeringsstof-koncentration/ingredienser",
            },
            {
                language: "tr",
                title: "İçindekiler",
                url: "https://www.lavita.de/mikro-besin-konsantresi/icindekiler",
            },
            {
                language: "cs",
                title: "Naše suroviny",
                url: "https://www.lavita.de/mikronutricni-koncentrat/suroviny",
            },
        ],
        id: "zutaten",
    },
    {
        translations: [
            {
                language: "de",
                title: "Nährwerte",
                url: "https://www.lavita.de/mikronaehrstoffkonzentrat/naehrwerte",
            },
            {
                language: "en",
                title: "Nutritional values",
                url: "https://www.lavita.de/micronutrient-concentrate/nutrition-table",
            },
            {
                language: "sv",
                title: "Näringsvärden",
                url: "https://www.lavita.de/koncentration-av-mikronaringsamnen/naringstabell",
            },
            {
                language: "fr",
                title: "Valeurs nutritionnelles",
                url: "https://www.lavita.de/concentration-en-micronutriments/tableau-nutritionnel",
            },
            {
                language: "it",
                title: "Valori nutrizionali",
                url: "https://www.lavita.de/micronutrienti-concentrati/tabella-nutrizionale",
            },
            {
                language: "nl",
                title: "Voedingswaarden",
                url: "https://www.lavita.de/micronutrient-concentraat/voedingstabel",
            },
            {
                language: "es",
                title: "Valores nutricionales",
                url: "https://www.lavita.de/micronutrientes-concentrados/tabla-nutricional",
            },
            {
                language: "no",
                title: "Ernæringsmessige verdier",
                url: "https://www.lavita.de/mikronaeringsstoff-konsentrasjon/naeringstabell",
            },
            {
                language: "da",
                title: "Næringsværdier",
                url: "https://www.lavita.de/mikronaeringsstof-koncentration/ernaeringstabel",
            },
            {
                language: "tr",
                title: "Besin Değerleri Tablosu",
                url: "https://www.lavita.de/mikro-besin-konsantresi/besin-degeri-tablosu",
            },
            {
                language: "cs",
                title: "Nutriční hodnoty",
                url: "https://www.lavita.de/mikronutricni-koncentrat/nutricni-hodnoty",
            },
        ],
        id: "naehrwerte",
    },
    {
        translations: [
            {
                language: "de",
                title: "Anwendung & Zubereitung",
                url: "https://www.lavita.de/service/anwendung-zubereitung",
            },
            {
                language: "en",
                title: "Preparation & How to use",
                url: "https://www.lavita.de/service/how-to-use",
            },
            {
                language: "sv",
                title: "Förberedelse och Hur det används",
                url: "https://www.lavita.de/service/hur-man-anvander",
            },
            {
                language: "fr",
                title: "Préparation et utilisation",
                url: "https://www.lavita.de/service/comment-lutiliser",
            },
            {
                language: "it",
                title: "Preparazione e uso",
                url: "https://www.lavita.de/servizio/come-si-usa",
            },
            {
                language: "nl",
                title: "Bereiding en gebruik",
                url: "https://www.lavita.de/service/hoe-te-gebruiken",
            },
            {
                language: "es",
                title: "Preparación y cómo usarlo",
                url: "https://www.lavita.de/servicio/como-utilizarlo",
            },
            {
                language: "no",
                title: "Forberedelse & Hvordan bruke den",
                url: "https://www.lavita.de/service/hvordan-bruke",
            },
            {
                language: "da",
                title: "Klargøring og brug",
                url: "https://www.lavita.de/service/sadan-bruger-du",
            },
            {
                language: "tr",
                title: "Kullanım",
                url: "https://www.lavita.de/mikro-besin-konsantresi/kullanim",
            },
            {
                language: "cs",
                title: "Příprava a způsob užívání",
                url: "https://www.lavita.de/informace/uzivani",
            },
        ],
        id: "anwendung_zubereitung",
        options: [],
    },
    {
        translations: [
            {
                language: "de",
                title: "Qualitätsversprechen",
                url: "https://www.lavita.de/mikronaehrstoffkonzentrat/qualitaetsversprechen",
            },
            {
                language: "en",
                title: "Quality Promise",
                url: "https://www.lavita.de/micronutrient-concentrate/quality-promise",
            },
            {
                language: "sv",
                title: "Löfte om kvalitet",
                url: "https://www.lavita.de/koncentration-av-mikronaringsamnen/lofte-om-kvalitet",
            },
            {
                language: "fr",
                title: "Promesse de qualité",
                url: "https://www.lavita.de/concentration-en-micronutriments/promesse-de-qualite",
            },
            {
                language: "it",
                title: "Promessa di qualità",
                url: "https://www.lavita.de/micronutrienti-concentrati/promessa-di-qualita",
            },
            {
                language: "nl",
                title: "Kwaliteitsbelofte",
                url: "https://www.lavita.de/micronutrient-concentraat/kwaliteitsbelofte",
            },
            {
                language: "es",
                title: "Promesa de calidad",
                url: "https://www.lavita.de/micronutrientes-concentrados/promesa-de-calidad",
            },
            {
                language: "no",
                title: "Løfte om kvalitet",
                url: "https://www.lavita.de/mikronaeringsstoff-konsentrasjon/lofte-om-kvalitet",
            },
            {
                language: "da",
                title: "Løfte om kvalitet",
                url: "https://www.lavita.de/mikronaeringsstof-koncentration/lofte-om-kvalitet",
            },
            {
                language: "tr",
                title: "Kalite Taahhüdü",
                url: "https://www.lavita.de/mikro-besin-konsantresi/kalite-taahhudu",
            },
            {
                language: "cs",
                title: "Záruka kvality",
                url: "https://www.lavita.de/mikronutricni-koncentrat/zaruka-kvality",
            },
        ],
        id: "qualitaetsversprechen",
    },
    {
        translations: [
            {
                language: "de",
                title: "Shop",
                url: "https://shop.lavita.de",
            },
            {
                language: "en",
                title: "Shop",
                url: "https://shop.lavita.de",
            },
            {
                language: "sv",
                title: "Butik",
                url: "https://shop.lavita.de",
            },
            {
                language: "fr",
                title: "Boutique",
                url: "https://shop.lavita.de",
            },
            {
                language: "it",
                title: "Shop",
                url: "https://shop.lavita.de",
            },
            {
                language: "nl",
                title: "Shop",
                url: "https://shop.lavita.de",
            },
            {
                language: "es",
                title: "Shop",
                url: "https://shop.lavita.de",
            },
            {
                language: "no",
                title: "Butikk",
                url: "https://shop.lavita.de",
            },
            {
                language: "da",
                title: "Butik",
                url: "https://shop.lavita.de",
            },
            {
                language: "tr",
                title: "Satın Alın",
                url: "https://shoptr.lavita.com",
            },
            {
                language: "cs",
                title: "E-shop",
                url: "https://shopcz.lavita.com",
            },
        ],
        id: "shop",
    },
    {
        translations: [
            {
                language: "de",
                title: "Häufige Fragen",
                url: "https://www.lavita.de/service/faq",
            },
            {
                language: "en",
                title: "FAQ",
                url: "https://www.lavita.de/service/faq",
            },
            {
                language: "sv",
                title: "FAQ",
                url: "https://www.lavita.de/service/faq",
            },
            {
                language: "fr",
                title: "FAQ",
                url: "https://www.lavita.de/service/faq",
            },
            {
                language: "it",
                title: "FAQ",
                url: "https://www.lavita.de/servizio/faq",
            },
            {
                language: "nl",
                title: "FAQ",
                url: "https://www.lavita.de/service/faq",
            },
            {
                language: "es",
                title: "FAQ",
                url: "https://www.lavita.de/servicio/faq",
            },
            {
                language: "no",
                title: "FAQ",
                url: "https://www.lavita.de/service/faq",
            },
            {
                language: "da",
                title: "FAQ",
                url: "https://www.lavita.de/service/faq",
            },
            {
                language: "tr",
                title: "Sık Sorulan Sorular",
                url: "https://www.lavita.de/mikro-besin-konsantresi/sik-sorulan-sorular",
            },
            {
                language: "cs",
                title: "Časté otázky",
                url: "https://www.lavita.de/informace/faq",
            },
        ],
        id: "haeufige_fragen",
    },
    {
        translations: [
            {
                language: "de",
                title: "LaVita App",
                url: "https://www.lavita.de/service/app",
            },
        ],
        id: "lavita_app",
    },
    {
        translations: [
            {
                language: "de",
                title: "Gutschein verschenken",
                hiddenRegion: [
                    "CH",
                    "AT",
                    "BE",
                    "CZ",
                    "CY",
                    "DK",
                    "EE",
                    "ES",
                    "FR",
                    "HR",
                    "IE",
                    "IS",
                    "IT",
                    "LV",
                    "LI",
                    "LT",
                    "LU",
                    "HU",
                    "MT",
                    "NL",
                    "NO",
                    "PL",
                    "PT",
                    "RO",
                    "SI",
                    "SK",
                    "FI",
                    "SE",
                    "TR",
                    "GR",
                    "BG",
                ],
                url: "https://www.lavita.de/geschenkbox",
            },
        ],
        id: "gutschein_verschenken",
    },
    {
        translations: [
            {
                language: "de",
                title: "Gutschein einlösen",
                url: "https://www.lavita.de/gutschein",
            },
        ],
        id: "gutschein_einloesen",
    },
    {
        translations: [
            {
                language: "de",
                title: "Telefonischer Kundenservice",
                url: "https://www.lavita.de/service/beratung",
            },
            {
                language: "tr",
                title: "Kişisel Danışmanlık",
                url: "https://www.lavita.de/mikro-besin-konsantresi/kisisel-danismanlik",
            },
        ],
        id: "telefonischer_kundenservice",
    },
    {
        translations: [
            {
                language: "de",
                title: "Familienunternehmen",
                url: "https://www.lavita.de/familienunternehmen",
            },
            {
                language: "en",
                title: "Family Business",
                url: "https://www.lavita.de/family-business",
            },
            {
                language: "sv",
                title: "Familjeföretag",
                url: "https://www.lavita.de/familjeforetag",
            },
            {
                language: "fr",
                title: "Entreprise familiale",
                url: "https://www.lavita.de/entreprise-familiale",
            },
            {
                language: "it",
                title: "Azienda familiare",
                url: "https://www.lavita.de/affari-di-famiglia",
            },
            {
                language: "nl",
                title: "Familiebedrijven",
                url: "https://www.lavita.de/familiebedrijf",
            },
            {
                language: "es",
                title: "Empresa familiar",
                url: "https://www.lavita.de/empresa-familiar",
            },
            {
                language: "no",
                title: "Familiebedrift",
                url: "https://www.lavita.de/familiebedrift",
            },
            {
                language: "da",
                title: "Familiefirma",
                url: "https://www.lavita.de/familievirksomhed",
            },
            {
                language: "cs",
                title: "Rodinná firma",
                url: "https://www.lavita.de/rodinna-firma",
            },
        ],
        id: "familienunternehmen",
    },
    {
        translations: [
            {
                language: "de",
                title: "Arbeiten bei LaVita",
                url: "https://www.lavita.de/karriere",
            },
        ],
        id: "arbeiten_bei_lavita",
    },
]

export { menu }
