import { HeaderBackground, HeaderStyle } from "../../../types"
import React, { ReactNode } from "react"
import { openOverlay, useOverlayDispatch, useOverlayState } from "../../../shared/context/overlay"

import Footer from "../../../shared/components/layout/footer"
import FooterMenu from "../../../shared/components/menu/footer"
import Header from "../../../shared/components/layout/header"
import HeaderMenu from "../../../shared/components/menu/header-v2"
import Loading from "../../../shared/components/miscellaneous/loading"
import Overlay from "../../../shared/components/overlay"
import Sitemap from "../../../components/sitemap-overlay"
import { findTranslation } from "../../../shared/utils/find-translation"
import { sitemapMenu } from "../../../config/sitemap"
import { useAuthState } from "../../../shared/context/auth"
import { useIsApp } from "../../../hooks/use-in-app"
import { useLocalesState } from "../../../shared/context/locales"
import { useTranslation } from "react-i18next"

interface LayoutProps {
    children: ReactNode
    headerBackground: HeaderBackground
    headerStyle: HeaderStyle
    landingPage?: boolean
    translations?: { language: string; slug: string }[]
}

const Layout = ({ children, headerBackground, headerStyle, landingPage, translations }: LayoutProps) => {
    const overlayDispatch = useOverlayDispatch()
    const { overlay } = useOverlayState()

    const { t } = useTranslation("translations")

    const { language, region } = useLocalesState()

    const { isPartner } = useAuthState()

    const isApp = useIsApp()

    return (
        <div className="antialiased">
            {!isApp && (
                <Header
                    configuration={{
                        account: !landingPage,
                        cart: !landingPage && region !== "TR" && region !== "CZ",
                        shop: true,
                        language: !landingPage && !isPartner,
                        topLevel: false,
                        search: false,
                        active: "website",
                        customTitle: undefined,
                        customTitleLink: undefined,
                        customLogo: undefined,
                        fixed: headerBackground === "transparent",
                        noShadow: false,
                        light: headerStyle === "negative",
                        logoLink: process.env.WEB_URL,
                        breadcrumbs: false,
                        customContact: false,
                        topLevelContact: true,
                    }}
                    menu={(light: boolean) => !landingPage && <HeaderMenu light={light} />}
                    translations={translations}
                />
            )}
            <main className="mb-auto">{children}</main>
            <Loading disableSSR disableReAuth />
            {!overlay && <Overlay />}
            {!isApp && (
                <>
                    <Footer
                        configuration={{
                            trustedShop: region === "DE",
                            socialMedia: true,
                            app: !landingPage && region !== "TR" && region !== "CZ",
                            contact: region !== "TR",
                            breadcrumbs: false,
                            customContact: false,
                        }}
                        menu={<FooterMenu />}
                        sitemap={
                            !!sitemapMenu.filter(s => findTranslation(s, language)).length && (
                                <button type="button" onClick={() => openOverlay(overlayDispatch, <Sitemap />)}>
                                    {t("footer_sitemap")}
                                </button>
                            )
                        }
                    />
                    {!!sitemapMenu.filter(s => findTranslation(s, language)).length && <Sitemap hidden />}
                </>
            )}
            {overlay}
        </div>
    )
}

export default Layout
