import React, { useState } from "react"
import { findTranslation, findTranslationsDirectus9 } from "../../utils/find-translation"
import { graphql, useStaticQuery } from "gatsby"

import { A } from "../../utils/router"
import ConditionalWrapper from "../miscellaneous/conditional-wrapper"
import HeaderMenuStyles from "./header-v2.module.css"
import { LazyImage } from "../universal/image"
import classNames from "classnames"
import { menu } from "../../config/menu"
import { useAuthState } from "../../context/auth"
import { useLocalesState } from "../../context/locales"
import { useTranslation } from "react-i18next"

const Sidebar = ({ contact, light, reset }) => {
    const { t } = useTranslation("shared")
    const { region, language } = useLocalesState()
    const { isPartner } = useAuthState()

    const {
        directus: { Menu: sidebarMenu },
    } = useStaticQuery(graphql`
        query {
            directus {
                Menu {
                    image_path
                    teaser_url
                    top_links {
                        translations {
                            languages_code {
                                code
                            }
                            title
                            url
                        }
                        id
                    }
                }
            }
        }
    `)

    if (!["DE", "AT"].includes(region) || language !== "de" || !sidebarMenu) return null

    return (
        <div className={classNames(HeaderMenuStyles.sidebar, { [HeaderMenuStyles.sidebarLight]: light })}>
            <h4>{t("header_menu_top_links")}</h4>
            <ul>
                {sidebarMenu.top_links.map(l => {
                    const translation = findTranslationsDirectus9(l.translations, language)
                    if (!translation) return null
                    return (
                        <li key={l.id}>
                            <A href={translation.url} onClick={reset}>
                                {translation.title}
                            </A>
                        </li>
                    )
                })}
            </ul>
            {sidebarMenu.image_path && (
                <div className={HeaderMenuStyles.imageWrapper}>
                    <ConditionalWrapper
                        condition={sidebarMenu.teaser_url}
                        wrapper={[
                            children => (
                                <A href={sidebarMenu.teaser_url} className={HeaderMenuStyles.image} onClick={reset}>
                                    {children}
                                </A>
                            ),
                            children => <div className={HeaderMenuStyles.image}>{children}</div>,
                        ]}
                    >
                        <LazyImage
                            url="assets"
                            path={sidebarMenu.image_path}
                            srcSet={[{ w: 342, default: true }, { w: 684 }]}
                            sizes="342px"
                        />
                    </ConditionalWrapper>
                </div>
            )}
            {contact && (
                <div>
                    <div className={HeaderMenuStyles.contact}>
                        <i className="lavita-icon"></i>
                        <div>
                            <strong>{t("header_menu_whatsapp")}</strong>
                            <a href={t("header_menu_whatsapp_url")}>{t("header_menu_whatsapp_number")}</a>
                        </div>
                    </div>
                    <div className={HeaderMenuStyles.contact}>
                        <i className="lavita-icon"></i>
                        <div>
                            <strong>{t("header_menu_phone")}</strong>
                            <a href={t(!isPartner ? "header_menu_phone_url" : "header_menu_phone_partner_url")}>
                                {t(!isPartner ? "header_menu_phone_number" : "header_menu_phone_partner_number")}
                            </a>
                        </div>
                    </div>
                </div>
            )}
        </div>
    )
}

const SubMenuItem = props => {
    const { language, region } = useLocalesState()

    const { options, reset } = props

    if (!findTranslation(props, language)?.title || findTranslation(props, language)?.hiddenRegion?.includes(region))
        return null

    if (findTranslation(props, language)?.url) {
        return (
            <A href={findTranslation(props, language).url} onClick={reset}>
                {findTranslation(props, language).title}
            </A>
        )
    }

    if (options) {
        return (
            <>
                <strong className={HeaderMenuStyles.heading}>{findTranslation(props, language)?.title}</strong>
                <div className={HeaderMenuStyles.options}>
                    {options.map(o => (
                        <SubMenuItem {...o} key={o.id} reset={reset} />
                    ))}
                </div>
            </>
        )
    }

    return null
}

const MenuItem = props => {
    const { t } = useTranslation("shared")
    const { language, region } = useLocalesState()

    const { options, setActive, id, active, reset } = props

    if (!findTranslation(props, language)?.title || findTranslation(props, language)?.hiddenRegion?.includes(region))
        return null

    if (findTranslation(props, language)?.url) {
        return (
            <div className={HeaderMenuStyles.mainHeading}>
                <A href={findTranslation(props, language).url} onClick={reset}>
                    {findTranslation(props, language).title}
                </A>
            </div>
        )
    }

    if (options) {
        return (
            <div className={classNames(HeaderMenuStyles.mainHeading, { [HeaderMenuStyles.active]: active })}>
                <button type="button" onClick={() => setActive(active => (active === id ? undefined : id))}>
                    {findTranslation(props, language)?.title}
                </button>
                <div className={HeaderMenuStyles.optionsWrapper}>
                    <div className={HeaderMenuStyles.optionsHeader}>
                        <button type="button" onClick={() => setActive()} className={HeaderMenuStyles.back}>
                            <i className="lavita-icon"></i>
                            <span>{t("back")}</span>
                        </button>
                    </div>
                    <div className={HeaderMenuStyles.optionsMain}>
                        <div className={HeaderMenuStyles.options}>
                            {options.map(o => (
                                <SubMenuItem {...o} key={o.id} reset={reset} />
                            ))}
                        </div>
                        <Sidebar light reset={reset} />
                    </div>
                </div>
            </div>
        )
    }

    return null
}

const HeaderMenu = ({ light }) => {
    const { t } = useTranslation("shared")

    const { region } = useLocalesState()

    const [opened, setOpened] = useState(false)
    const [active, setActive] = useState()

    const reset = () => {
        setOpened(false)
        setActive()
        document.body.classList.remove("noScroll")
    }

    return (
        <>
            <button
                className={classNames(HeaderMenuStyles.button, { [HeaderMenuStyles.light]: light || opened })}
                onClick={() =>
                    setOpened(open => {
                        if (open) {
                            setActive()
                            document.body.classList.remove("noScroll")
                        } else {
                            document.body.classList.add("noScroll")
                        }
                        return !open
                    })
                }
            >
                <div className={classNames(HeaderMenuStyles.hamburger, { [HeaderMenuStyles.close]: opened })}>
                    <div className={HeaderMenuStyles.line} />
                    <div className={HeaderMenuStyles.line} />
                </div>
                <span>{t(opened ? "header_menu_close" : "header_menu_open")}</span>
            </button>
            <div className={classNames(HeaderMenuStyles.menu, { [HeaderMenuStyles.opened]: opened })}>
                <div className={HeaderMenuStyles.scroll}>
                    <div className={HeaderMenuStyles.container}>
                        <div className={HeaderMenuStyles.main}>
                            <nav
                                className={classNames(HeaderMenuStyles.navigation, {
                                    [HeaderMenuStyles.activeNavigation]: active,
                                })}
                            >
                                {menu.map(m => (
                                    <MenuItem
                                        {...m}
                                        key={m.id}
                                        level={0}
                                        setActive={setActive}
                                        reset={reset}
                                        active={active === m.id}
                                    />
                                ))}
                            </nav>
                            <Sidebar contact reset={reset} />
                        </div>
                        <div
                            className={classNames(HeaderMenuStyles.footer, {
                                [HeaderMenuStyles.hidden]: active,
                            })}
                        >
                            {region !== "TR" && (
                                <A href={`${process.env.WEB_URL}/${t("footer_legalnotice_path")}`} onClick={reset}>
                                    {t("footer_legalnotice")}
                                </A>
                            )}
                            <A href={`${process.env.WEB_URL}/${t("footer_dataprotection_path")}`} onClick={reset}>
                                {t("footer_dataprotection")}
                            </A>
                            <A href={`${process.env.WEB_URL}/${t("footer_terms_and_conditions_path")}`} onClick={reset}>
                                {t("footer_terms_and_conditions")}
                            </A>
                            {region !== "TR" && (
                                <A href={`${process.env.WEB_URL}/${t("footer_revocation_path")}`} onClick={reset}>
                                    {t("footer_revocation")}
                                </A>
                            )}
                            {typeof window !== "undefined" && window.CCM && (
                                <button onClick={() => window.CCM.openControlPanel()}>{t("footer_cookie")}</button>
                            )}
                            <A href={`${process.env.WEB_URL}/${t("footer_contact_path")}`} onClick={reset}>
                                {t("footer_contact")}
                            </A>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default React.memo(HeaderMenu)
