import React from "react"

export interface TableProps {
    head: {
        id: string
        text: string
        asterisk?: string
    }[]
    body: {
        id: string
        text: string
    }[][]
}

const Table = ({ head, body }: TableProps) => {
    return (
        <table className="w-full overflow-hidden text-xs sm:rounded-lg sm:text-md">
            <tbody>
                <tr>
                    {head.map(item => (
                        <th
                            key={item.id}
                            className="border border-solid border-calypso bg-calypso py-2.5 text-left text-white first:pl-6.5 last:pr-6.5 sm:py-5 sm:first:pl-5 sm:last:pr-5 md:first:pl-10 md:last:pr-10 lg:first:pl-15 lg:last:pr-15 xl:first:pl-20 xl:last:pr-20"
                        >
                            {item.text}
                            {item.asterisk && <sup>{item.asterisk}</sup>}
                        </th>
                    ))}
                </tr>
                {body.map((row, i) => (
                    <tr
                        key={i}
                        className="border border-solid border-border-tide odd:bg-background-tide sm:rounded-b-lg sm:last:shadow-[0_1px_0_#e5e2de,_-1px_0_0_#e5e2de,_1px_0_0_#e5e2de]"
                    >
                        {row.map(item => (
                            <td
                                key={item.id}
                                className="whitespace-pre-wrap py-2.5 text-left first:pl-6.5 last:pr-6.5 sm:py-5 sm:first:pl-5 sm:last:pr-5 md:first:pl-10 md:last:pr-10 lg:first:pl-15 lg:last:pr-15 xl:first:pl-20 xl:last:pr-20"
                            >
                                {item.text === "check" ? <i className="lavita-icon"></i> : item.text}
                            </td>
                        ))}
                    </tr>
                ))}
            </tbody>
        </table>
    )
}

export default Table
