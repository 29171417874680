import React, { ReactNode } from "react"

import classNames from "classnames"
import { options } from "../../../types/options"
import { twMerge } from "tailwind-merge"

export enum GridColumns {
    "Three" = "3",
    "Two" = "2",
    "One" = "1",
}

export enum GridGap {
    "Base" = "base",
    "Large" = "lg",
}

export interface GridProps {
    children: ReactNode
    columns?: GridColumns
    gapY?: GridGap
    gapX?: GridGap
}

const Grid = ({ children, columns = GridColumns.Three, gapY = GridGap.Base, gapX = GridGap.Base }: GridProps) => {
    return (
        <div
            className={twMerge(
                classNames("grid grid-cols-1 gap-x-5 gap-y-5 sm:grid-cols-2 lg:gap-x-6 lg:gap-y-6", {
                    "gap-y-7 sm:gap-y-10 lg:gap-y-16 xl:gap-y-24": gapY === GridGap.Large,
                    "gap-x-5 lg:gap-x-10": gapX === GridGap.Large,
                    "sm:grid-cols-2": columns === GridColumns.Two || columns == GridColumns.Three,
                    "md:grid-cols-3": columns === GridColumns.Three,
                    "sm:grid-cols-1": columns === GridColumns.One,
                })
            )}
        >
            {children}
        </div>
    )
}

export const gridColumnOptions = options<typeof GridColumns>(GridColumns)
export const gridColumnOneTwoOptions = gridColumnOptions.filter(option => option.value !== GridColumns.Three)
export const gridColumnTwoThreeOptions = gridColumnOptions.filter(option => option.value !== GridColumns.One)

export const gridGapOptions = options<typeof GridGap>(GridGap)

export default Grid
