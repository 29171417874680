import { Image } from "react-bricks/frontend"
import React from "react"

export enum AspectRatio {
    "1/1" = "1",
    "5/4" = "1.25",
    "5/2" = "2.5",
    "3/1" = "3",
    "3/2" = "1.5",
    "4/5" = "0.8",
    "15/9" = "1.67",
    "16/9" = "1.77",
    "5/8" = "0.625",
    "9/2" = "4.5",
}

export interface ImageEditorProps {
    aspectRatio?: AspectRatio
    alt?: string
    propName?: string
    maxWidth?: number
    className?: string
}

const ImageEditor = ({ aspectRatio, alt = "", propName = "image", maxWidth, className }: ImageEditorProps) => {
    return (
        <Image
            aspectRatio={aspectRatio && parseFloat(aspectRatio)}
            alt={alt}
            propName={propName}
            maxWidth={maxWidth}
            imageClassName={className}
        />
    )
}

export default ImageEditor
