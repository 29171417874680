import { ContainerBackgroundColor, ContainerVariant, RoundedBackgroundContainer } from "../components/layout"
import Typography, { TypographyElement } from "../Typography"

import { Color } from "../components/typography"
import { Icon } from "../components/icon"
import { Pictograms } from "../../types"
import React from "react"
import { types } from "react-bricks/frontend"

export interface QuoteTextProps {
    headline: TypographyElement[]
    text: TypographyElement[]
    index?: number
}

const QuoteText: types.Brick<QuoteTextProps> = ({ index }) => {
    return (
        <div key={index}>
            <Color>
                <RoundedBackgroundContainer
                    background={ContainerBackgroundColor.Grey}
                    variant={ContainerVariant.Small}
                    className="flex flex-col"
                >
                    <Icon icon={Pictograms.quote_outline} className="text-3xl leading-none text-border-copperfield" />
                    <Typography propName="headline" allowList={["sup", "link", "bold"]} />
                    <Typography block="copytext-small" allowList={["small", "sup", "link"]} />
                </RoundedBackgroundContainer>
            </Color>
        </div>
    )
}

QuoteText.schema = {
    name: "QuoteText",
    label: "Quote Text",
    hideFromAddMenu: true,
    getDefaultProps: (): QuoteTextProps => ({
        headline: [
            {
                type: "paragraph",
                children: [
                    {
                        bold: true,
                        text: "Lorem ipsum dolor sit amet.",
                    },
                ],
            },
        ],
        text: [
            {
                type: "paragraph",
                children: [
                    {
                        text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc placerat sagittis faucibus.",
                    },
                ],
            },
        ],
    }),
}

export default QuoteText
