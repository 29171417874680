import { ToastContainer, cssTransition } from "react-toastify"

import { AuthProvider } from "../shared/context/auth"
import CloseButtonToast from "../shared/components/miscellaneous/close-button-toast"
import { I18nextProvider } from "react-i18next"
import { LoadingProvider } from "../shared/context/loading"
import { LocalesProvider } from "../shared/context/locales"
import { OverlayProvider } from "../shared/context/overlay"
import { PageProvider } from "../context/page"
import { PopperProvider } from "../shared/context/popper"
import React from "react"
import { ReactBricks } from "react-bricks/frontend"
import { ReactQueryDevtools } from "@tanstack/react-query-devtools"
import { Script } from "gatsby"
import Whatsapp from "../components-v2/miscellaneous/whatsapp"
import config from "../react-bricks/config"
import i18next from "../i18n/config"

const WrapPageDefault = ({ element, props }) => {
    if (
        i18next.language !==
        (props.pageContext.language?.split("_")[0] || process.env.DEFAULT_LANGUAGE) +
            (props.pageContext.region === "CH" ? "-CH" : "")
    ) {
        i18next.changeLanguage(
            (props.pageContext.language?.split("_")[0] || process.env.DEFAULT_LANGUAGE) +
                (props.pageContext.region === "CH" ? "-CH" : "")
        )
    }

    const fadeFromRightOrTop = cssTransition({
        enter: "slideIn",
        exit: "slideOut",
    })

    return (
        <>
            {!props.path.includes("/admin") ? (
                <ToastContainer
                    autoClose={false}
                    hideProgressBar
                    transition={fadeFromRightOrTop}
                    closeButton={<CloseButtonToast />}
                    theme="colored"
                    icon={false}
                />
            ) : null}
            <PageProvider
                value={{
                    ...props.pageContext,
                    region: props.pageContext.region,
                }}
            >
                {props.path === "/de-de/business" || props.path === "/de-at/business" ? (
                    <>
                        <Script>
                            {`!(function (s, a, l, e, sv, i, ew, er) {try {(a =s[a] || s[l] || function () {throw "no_xhr";}),(sv = i = "https://salesviewer.org"),(ew = function(x){(s = new Image()), (s.src = "https://salesviewer.org/tle.gif?sva=O1R2A4T1l5C1&u="+encodeURIComponent(window.location)+"&e=" + encodeURIComponent(x))}),(l = s.SV_XHR = function (d) {return ((er = new a()),(er.onerror = function () {if (sv != i) return ew("load_err");(sv = "https://www.salesviewer.com/t"), setTimeout(l.bind(null, d), 0);}),(er.onload = function () {(s.execScript || s.eval).call(er, er.responseText);}),er.open("POST", sv, !0),(er.withCredentials = true),er.send(d),er);}),l("h_json=" + 1 * ("JSON" in s && void 0 !== JSON.parse) + "&h_wc=1&h_event=" + 1 * ("addEventListener" in s) + "&sva=" + e);} catch (x) {ew(x)}})(window, "XDomainRequest", "XMLHttpRequest", "O1R2A4T1l5C1");`}
                        </Script>
                        <noscript>
                            <img src="https://salesviewer.org/O1R2A4T1l5C1.gif" style={{ visibility: "hidden" }} />
                        </noscript>
                        <Script src="https://assets.calendly.com/assets/external/widget.js" />
                    </>
                ) : null}
                <I18nextProvider i18n={i18next}>
                    <ReactBricks {...config}>
                        <LocalesProvider
                            language={props.pageContext?.language || process.env.DEFAULT_LANGUAGE}
                            region={props.pageContext?.region || process.env.REGION}
                        >
                            <OverlayProvider>
                                <PopperProvider>
                                    <LoadingProvider>
                                        <AuthProvider>
                                            {element}
                                            <Whatsapp />
                                            {process.env.NODE_ENV === "development" && (
                                                <ReactQueryDevtools initialIsOpen={false} />
                                            )}
                                        </AuthProvider>
                                    </LoadingProvider>
                                </PopperProvider>
                            </OverlayProvider>
                        </LocalesProvider>
                    </ReactBricks>
                </I18nextProvider>
            </PageProvider>
        </>
    )
}

export default WrapPageDefault
