import { Background, BackgroundColor, Container, Spacer, SpacerVariant, backgroundOptions } from "../components/layout"
import { Color, TypographyColor } from "../components/typography"
import Image, { AspectRatio } from "../Image"
import { ImageTextCircle, PositionCircle } from "../components/image-text-circle"
import { Repeater, types } from "react-bricks/frontend"
import Typography, { TypographyElement } from "../Typography"

import { CircleColor } from "../components/circle"
import { ConditionalWrapper } from "../components/util"
import { GridSmCol } from "../components/grid"
import { IconText } from "../components/icon-text"
import { Pictograms } from "../../types"
import React from "react"
import classNames from "classnames"
import { useCheckForEmptyText } from "../../hooks/use-check-for-empty-text"

interface MasterImageTextCircleProps {
    background: { color: BackgroundColor } | BackgroundColor
    circle: boolean
    reversePosition: boolean
    imageBottom: boolean
    headline: TypographyElement[]
    text: TypographyElement[]
    spacerTop: boolean
    spacerBottom: boolean
    roundedImage: boolean
    quoteIcon: boolean
}

const MasterImageTextCircle: types.Brick<MasterImageTextCircleProps> = ({
    background,
    circle,
    reversePosition,
    imageBottom,
    spacerTop,
    spacerBottom,
    roundedImage,
    quoteIcon,
    headline,
}) => {
    const emptyHeadline = useCheckForEmptyText(headline)

    const bgColor = typeof background === "object" ? background.color : background

    return (
        <Background color={bgColor} className="overflow-hidden sm:overflow-visible">
            <Spacer variant={!spacerTop ? SpacerVariant.None : undefined} />
            <Container>
                <Repeater propName="centerText" />
                <Color color={bgColor === BackgroundColor.Green ? TypographyColor.White : TypographyColor.Darkgrey}>
                    <GridSmCol>
                        <div
                            className={classNames("z-1 space-y-5 sm:flex sm:flex-col sm:place-content-center", {
                                "sm:order-2": !reversePosition,
                                "sm:order-1": reversePosition,
                                "hidden sm:flex": emptyHeadline,
                            })}
                        >
                            <ConditionalWrapper condition={quoteIcon} wrapper={children => <div>{children}</div>}>
                                <>
                                    {quoteIcon && (
                                        <IconText
                                            icon={Pictograms.quote_outline}
                                            className={classNames("mb-4 sm:mb-5", {
                                                "text-white": bgColor === BackgroundColor.Green,
                                            })}
                                        />
                                    )}
                                    <Typography
                                        propName="headline"
                                        allowList={["h3", "h4", "bold", "sup", "link", "quote"]}
                                    />
                                </>
                            </ConditionalWrapper>
                            <div className={classNames("space-y-7 sm:block md:space-y-10", { hidden: !imageBottom })}>
                                <Typography allowList={["ul", "small", "sup", "link", "bold"]} />
                                <Repeater
                                    propName="repeater"
                                    renderWrapper={children => (
                                        <div className="flex flex-col space-y-5 sm:space-y-7">{children}</div>
                                    )}
                                />
                            </div>
                        </div>
                        <div
                            className={classNames("order-2", {
                                "sm:order-1": !reversePosition,
                                "sm:order-2": reversePosition,
                            })}
                        >
                            <ConditionalWrapper
                                condition={circle}
                                wrapper={children => (
                                    <ImageTextCircle
                                        color={
                                            bgColor === BackgroundColor.Green ? CircleColor.Green : CircleColor.Orange
                                        }
                                        positionCircle={reversePosition ? PositionCircle.Left : PositionCircle.Right}
                                    >
                                        <div className="relative z-2">{children}</div>
                                    </ImageTextCircle>
                                )}
                            >
                                <Image
                                    maxWidth={717}
                                    aspectRatio={AspectRatio["4/5"]}
                                    className={classNames("overflow-hidden", {
                                        "ml-auto": reversePosition,
                                        "rounded-base sm:rounded-lg": roundedImage,
                                    })}
                                />
                            </ConditionalWrapper>
                        </div>
                        <div className={classNames("order-3 space-y-7 sm:hidden", { hidden: imageBottom })}>
                            <Typography allowList={["ul", "small", "sup", "link", "bold"]} />
                            <Repeater
                                propName="repeater"
                                renderWrapper={children => <div className="flex flex-col space-y-5">{children}</div>}
                            />
                        </div>
                    </GridSmCol>
                </Color>
            </Container>
            <Spacer variant={!spacerBottom ? SpacerVariant.None : undefined} />
        </Background>
    )
}

MasterImageTextCircle.schema = {
    name: "MasterImageTextCircle",
    label: "Master Image Text Circle",
    repeaterItems: [
        {
            name: "repeater",
            items: [
                {
                    type: "Button",
                    label: "Button",
                    max: 1,
                },
                {
                    type: "TextLink",
                    label: "Text Link",
                    max: 3,
                },
                {
                    type: "DecorativeText",
                    label: "Decorative Text",
                    max: 1,
                },
            ],
        },
        {
            name: "centerText",
            items: [
                {
                    type: "CenterText",
                    label: "Text",
                    max: 1,
                },
            ],
        },
    ],
    sideEditProps: [
        {
            name: "background",
            label: "Background",
            type: types.SideEditPropType.Select,
            selectOptions: {
                display: types.OptionsDisplay.Color,
                options: backgroundOptions,
            },
        },
        {
            name: "roundedImage",
            label: "Rounded image",
            type: types.SideEditPropType.Boolean,
        },
        {
            name: "circle",
            label: "Circle",
            type: types.SideEditPropType.Boolean,
        },
        {
            name: "reversePosition",
            label: "Reverse Position",
            type: types.SideEditPropType.Boolean,
        },
        {
            name: "imageBottom",
            label: "Image Bottom",
            type: types.SideEditPropType.Boolean,
        },
        {
            name: "quoteIcon",
            label: "Quote Icon",
            type: types.SideEditPropType.Boolean,
        },
        {
            groupName: "Spacer",
            props: [
                {
                    name: "spacerTop",
                    label: "Spacer Top",
                    type: types.SideEditPropType.Boolean,
                },
                {
                    name: "spacerBottom",
                    label: "Spacer Bottom",
                    type: types.SideEditPropType.Boolean,
                },
            ],
        },
    ],
    getDefaultProps: (): MasterImageTextCircleProps => ({
        background: { color: BackgroundColor.White },
        circle: true,
        reversePosition: false,
        imageBottom: false,
        quoteIcon: false,
        headline: [
            {
                type: "h3",
                children: [
                    {
                        text: "Lorem ipsum dolor sit amet, consetetur sadipscing.",
                    },
                ],
            },
        ],
        text: [
            {
                type: "paragraph",
                children: [
                    {
                        text: "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore.",
                    },
                ],
            },
        ],
        spacerTop: true,
        spacerBottom: true,
        roundedImage: true,
    }),
}

export default MasterImageTextCircle
