import { useEffect, useMemo, useState } from "react"

import { getWindow } from "../utils/mock-window"

const setQueryToCurrentUrl = params => {
    const url = new URL(getWindow().location.href)

    Object.keys(params).forEach(key => {
        const value = params[key]
        if (value === null || value === undefined) {
            return url.searchParams.delete(key)
        }
        return url.searchParams.set(key, typeof value === "object" ? JSON.stringify(value) : value)
    })
    return url
}

const useUrlSearchParams = () => {
    const [, forceUpdate] = useState()

    const locationSearch = getWindow().location.search

    const urlSearchParams = useMemo(() => {
        return new URLSearchParams(locationSearch)
    }, [locationSearch])

    const params = useMemo(() => {
        let params = {}
        for (let item of urlSearchParams) {
            try {
                params[item[0]] = JSON.parse(item[1])
            } catch {
                params[item[0]] = item[1]
            }
        }
        return params
    }, [urlSearchParams])

    const redirectToNewSearchParams = params => {
        const url = setQueryToCurrentUrl(params)

        if (getWindow().location.search !== url.search) {
            getWindow().history.replaceState({}, "", url)
        }
        if (urlSearchParams.toString() !== url.searchParams.toString()) {
            forceUpdate({})
        }
    }

    useEffect(() => {
        redirectToNewSearchParams(params)
        // eslint-disable-next-line
    }, [params])

    const setParams = params => {
        redirectToNewSearchParams(params)
    }

    useEffect(() => {
        const onPopState = () => {
            forceUpdate({})
        }
        getWindow().addEventListener("popstate", onPopState)
        return () => {
            getWindow().removeEventListener("popstate", onPopState)
        }
    }, [])

    return [params, setParams]
}

export { useUrlSearchParams }
