import { openOverlay, useOverlayDispatch } from "../../shared/context/overlay"

import React from "react"
import WhatsappOverlay from "../overlay/whatsapp"
import WhatsappStyles from "./whatsapp.module.css"
import { useIsApp } from "../../hooks/use-in-app"
import { useLocalesState } from "../../shared/context/locales"

const Whatsapp = () => {
    const overlayDispatch = useOverlayDispatch()
    const { region } = useLocalesState()

    // partner landing pages without whatsapp button
    const urlsToCheck = ["partnerschaft", "mitarbeitergeschenk", "hebammen", "business"]

    const isUrlOpen = url => {
        if (typeof window !== "undefined") {
            return window.location.href.includes(url)
        }
    }

    const openUrls = urlsToCheck.filter(isUrlOpen)

    const isApp = useIsApp()

    if (openUrls.length > 0 || isApp || region === "CZ") return null

    return (
        <button
            className={WhatsappStyles.button}
            type="button"
            onClick={() => openOverlay(overlayDispatch, <WhatsappOverlay />)}
        >
            <i className="lavita-icon"></i>
        </button>
    )
}

export default Whatsapp
