import React, { ReactNode } from "react"

import classNames from "classnames"
import { colorOptions } from "../../../types/options"
import { twMerge } from "tailwind-merge"

export enum ContainerBackgroundColor {
    "Transparent" = "transparent",
    "Grey" = "#f2f0ee",
    "White" = "#fff",
}

export enum ContainerVariant {
    "Small" = "sm",
    "Base" = "base",
}

interface ContainerProps {
    children: ReactNode
    background?: ContainerBackgroundColor
    variant?: ContainerVariant
    className?: string
}

const RoundedBackgroundContainer = ({
    children,
    background = ContainerBackgroundColor.Grey,
    variant = ContainerVariant.Base,
    className,
}: ContainerProps) => {
    return (
        <div
            className={twMerge(
                classNames(
                    "space-y-4 sm:space-y-5",
                    {
                        "rounded-base  p-3.75 sm:rounded-lg sm:p-5":
                            background === ContainerBackgroundColor.Grey ||
                            background === ContainerBackgroundColor.White,
                        "bg-background-tide": background === ContainerBackgroundColor.Grey,
                        "bg-white": background === ContainerBackgroundColor.White,
                        "lg:p-15":
                            (background === ContainerBackgroundColor.Grey ||
                                background === ContainerBackgroundColor.White) &&
                            variant === ContainerVariant.Base,
                        "lg:p-7.5":
                            (background === ContainerBackgroundColor.Grey ||
                                background === ContainerBackgroundColor.White) &&
                            variant === ContainerVariant.Small,
                    },
                    className
                )
            )}
        >
            {children}
        </div>
    )
}

export const containerBackgroundOptions = colorOptions<typeof ContainerBackgroundColor>(ContainerBackgroundColor)

export default RoundedBackgroundContainer
