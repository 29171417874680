import { Background, BackgroundColor, Container, Spacer, SpacerVariant } from "../components/layout"
import { Repeater, types, usePageValues } from "react-bricks/frontend"

import { ColumnProductProps } from "./ColumnProduct"
import { EqualHeight } from "react-equal-height/clean"
import type { Product } from "@lavita-io/international-sdk"
import React from "react"
import classNames from "classnames"
import client from "../../shared/utils/client"
import { options } from "../../types"
import { twMerge } from "tailwind-merge"
import { useLocalesState } from "../../shared/context/locales"
import { usePageContext } from "../../context/page"
import { useQuery } from "@tanstack/react-query"
import { useTranslation } from "react-i18next"

export enum GridColumns {
    "Three" = "3",
    "Two" = "2",
}

export interface ThreeColumnProductsProps {
    repeater?: {
        id: string
        props: ColumnProductProps
        type: "Product"
    }[]
    spacerTop: boolean
    spacerBottom: boolean
    id?: string
    columns?: GridColumns
}

const ThreeColumnProducts: types.Brick<ThreeColumnProductsProps> = ({
    repeater,
    spacerTop,
    spacerBottom,
    id,
    columns = GridColumns.Three,
}) => {
    const { t } = useTranslation("translations")
    const { region } = useLocalesState()
    const { page: pageContext } = usePageContext()

    const [pageValues] = usePageValues()

    const page = pageContext || pageValues

    const { data } = useQuery<Product[]>(
        ["products", ...(repeater?.map(r => r.props.sku).filter(r => r) || []), region, page?.language],
        () =>
            client.getProducts(
                { sku: repeater?.map(r => r.props.sku).filter(r => r), country: region },
                page?.language
            ),
        {
            enabled: !!repeater?.length && !!page,
        }
    )

    return (
        <Background color={BackgroundColor.Grey}>
            <Container>
                <Spacer variant={!spacerTop ? SpacerVariant.None : undefined} />
                <EqualHeight>
                    <div
                        className={twMerge(
                            classNames("grid grid-cols-1 gap-x-3.5 gap-y-5 sm:grid-cols-3 md:gap-x-5 lg:gap-x-6", {
                                "sm:grid-cols-2": data?.length === 2 || columns === GridColumns.Two,
                            })
                        )}
                        id={id}
                    >
                        <Repeater
                            propName="repeater"
                            itemProps={
                                {
                                    products: data,
                                    wide: data?.length === 2 || columns === GridColumns.Two,
                                } as ColumnProductProps
                            }
                        />
                    </div>
                </EqualHeight>
                <Spacer variant={!spacerBottom ? SpacerVariant.None : undefined} />
            </Container>
        </Background>
    )
}

ThreeColumnProducts.schema = {
    name: "ThreeColumnProducts",
    label: "3 Column Products",
    repeaterItems: [
        {
            name: "repeater",
            items: [
                {
                    type: "Product",
                    label: "Product",
                    min: 2,
                    max: 3,
                },
            ],
        },
    ],
    sideEditProps: [
        {
            name: "id",
            label: "ID",
            type: types.SideEditPropType.Text,
        },
        {
            name: "columns",
            label: "Columns",
            type: types.SideEditPropType.Select,
            selectOptions: {
                options: options<typeof GridColumns>(GridColumns),
                display: types.OptionsDisplay.Select,
            },
        },
        {
            groupName: "Spacer",
            props: [
                {
                    name: "spacerTop",
                    label: "Spacer Top",
                    type: types.SideEditPropType.Boolean,
                },
                {
                    name: "spacerBottom",
                    label: "Spacer Bottom",
                    type: types.SideEditPropType.Boolean,
                },
            ],
        },
    ],
    getDefaultProps: (): ThreeColumnProductsProps => ({
        spacerTop: true,
        spacerBottom: true,
        columns: GridColumns.Three,
    }),
}

export default ThreeColumnProducts
