import {
    audioBookSvg,
    bagSvg,
    calendarSvg,
    checkSvg,
    clockSvg,
    featherSvg,
    femaleSvg,
    flowerSvg,
    giftBoxSvg,
    handshakeSvg,
    heartMailv2Svg,
    keySvg,
    leafSVg,
    mailSvg,
    maleSvg,
    mapSvg,
    mediaSvg,
    pdfSvg,
    personenSvg,
    sandclockSvg,
    studySvg,
    threeBottlesBoxSvg,
    tippSvg,
    uniSvg,
} from "../../styles/svg"

import React from "react"

const Icon = ({ icon, variant, backgroundColor, color }) => {
    const icons = {
        uni: uniSvg,
        group: personenSvg,
        female: femaleSvg,
        male: maleSvg,
        study: studySvg,
        leaf: leafSVg,
        audiobook: audioBookSvg,
        mail: mailSvg,
        pdf: pdfSvg,
        bag: bagSvg,
        giftbox: giftBoxSvg,
        heartmail: heartMailv2Svg,
        handshake: handshakeSvg,
        threebottlesbox: threeBottlesBoxSvg,
        key: keySvg,
        media: mediaSvg,
        feather: featherSvg,
        tipp: tippSvg,
        flower: flowerSvg,
        check: checkSvg,
        sandclock: sandclockSvg,
        clock: clockSvg,
        map: mapSvg,
        calendar: calendarSvg,
    }
    return (
        <div className={variant} style={{ backgroundColor: backgroundColor }}>
            {icons[icon] ? (
                icons[icon]
            ) : (
                <i style={{ color: color }} className="lavita-icon">
                    {icon}
                </i>
            )}
        </div>
    )
}

export default React.memo(Icon)
