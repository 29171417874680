import React, { useState } from "react"

import BigRadio from "../../../components/fields/radio/big"
import FirmenGesundheitFormStyles from "./firmengesundheit.module.css"
import FlexWrapper from "../../../shared/components/fields/flex-wrapper"
import IconAction from "../../../shared/components/universal/icon-action"
import Input from "../../../shared/components/fields/input"
import { LazyImage } from "../../../shared/components/universal/image"
import LinkButtonWrapper from "../universal/link-button-wrapper"
import Radio from "../../../shared/components/fields/radio"
import ReactMarkdown from "react-markdown"
import Spacer from "../universal/spacer"
import Text from "../../../components-v2/new/matrix/text"
import classNames from "classnames"
import { genders } from "../../../shared/config/genders"
import { scrollTo } from "../../../shared/utils/smooth-scroll"
import { toast } from "react-toastify"
import { useDimensions } from "../../../shared/hooks/use-dimensions"
import { useForm } from "react-hook-form"
import { usePostContact } from "../../../hooks/contact/use-query-contact"
import { useTranslation } from "react-i18next"
import { useUrlSearchParams } from "../../../shared/hooks/use-url-search-params"

const FirmenGesundheitForm = () => {
    const { t } = useTranslation("translations")
    const [isSuccess, setIsSuccess] = useState(false)

    const {
        handleSubmit,
        control,
        reset,
        register,
        formState: { errors },
    } = useForm({
        mode: "onTouched",
        defaultValues: {
            salutation: genders[0].id,
            company_name: "",
            firstname: "",
            lastname: "",
            street: "",
            postcode: "",
            city: "",
            phone: "",
            email: "",
            recognized: "",
        },
        context: undefined,
        criteriaMode: "firstError",
        shouldFocusError: true,
    })

    const [params] = useUrlSearchParams()
    const mutate = usePostContact()

    const submit = async values => {
        const body = {
            category_id: process.env.CONTACT_BGM_CATEGORY_ID,
            firstname: values.firstname,
            lastname: values.lastname,
            email: values.email,
            phone: values.phone,
            body: t("company_health_submit_body", {
                salutation: genders.find(g => g.id === values.salutation).visibleInAddress
                    ? t(genders.find(g => g.id === values.salutation).name)
                    : "",
                company_name: values.company_name,
                firstname: values.firstname,
                lastname: values.lastname,
                phone: values.phone,
                email: values.email,
                street: values.street,
                postcode: values.postcode,
                city: values.city,
                recognized: values.recognized,
            }),
        }

        if (params?.utm_source) {
            body.body += `\n\nutm_source: ${params.utm_source}\nutm_medium: ${params.utm_medium}\nutm_campaign: ${params.utm_campaign}`
        }

        try {
            await mutate.mutateAsync(body, { throwError: true })
            toast.success(t("contact_form_success"), { autoClose: 5000 })
            reset()
            setIsSuccess(true)
            scrollTo("success_message")
        } catch (error) {
            return
        }
    }

    const { width } = useDimensions()

    const [active, setActive] = useState()

    return (
        <div className={FirmenGesundheitFormStyles.wrapper}>
            {!isSuccess ? (
                <div className={FirmenGesundheitFormStyles.form}>
                    <h3>{t("company_health_appointment_headline")}</h3>
                    <p>
                        <strong>{t("company_health_appointment_text_bold")}</strong>{" "}
                        {t("company_health_appointment_text")}
                    </p>
                    <Spacer small />
                    <div>
                        <BigRadio
                            id={0}
                            name="appointment"
                            active={active}
                            block={
                                <>
                                    <Text small source={t("company_health_appointment_tel")} />
                                    {active === 0 && (
                                        <>
                                            <Spacer small />
                                            <p>{t("company_health_appointment_tel_video_text")}</p>
                                            <Spacer small />
                                        </>
                                    )}
                                    <div
                                        className="calendly-inline-widget"
                                        data-url="https://calendly.com/d/34g-xbs-yfc/beratungsgesprach-zu-lavita-fur-unternehmen-15min?hide_gdpr_banner=1&hide_event_type_details=1&text_color=444444&primary_color=00497a"
                                        style={{
                                            minWidth: "320px",
                                            height: "700px",
                                            display: active === 0 ? "block" : "none",
                                        }}
                                    />
                                </>
                            }
                            onClick={() => setActive(0)}
                        />
                        <BigRadio
                            id={1}
                            name="contact"
                            active={active}
                            block={
                                <form onSubmit={handleSubmit(submit)} name="firmengesundheit">
                                    <Text small source={t("company_health_appointment_contact_data")} />
                                    {active === 1 && (
                                        <>
                                            <Spacer small />
                                            <div>
                                                <span className={FirmenGesundheitFormStyles.label}>
                                                    <strong>{t("company_health_company")}</strong>
                                                </span>
                                                <Input
                                                    control={control}
                                                    rules={{
                                                        required: {
                                                            value: true,
                                                            message: t("form_error_required"),
                                                        },
                                                    }}
                                                    type="text"
                                                    name="company_name"
                                                    label={t("company_health_company_name")}
                                                />
                                                <Input
                                                    control={control}
                                                    type="text"
                                                    name="street"
                                                    label={t("company_health_company_street")}
                                                />
                                                <FlexWrapper childWidths={[30, 70]}>
                                                    <Input
                                                        control={control}
                                                        rules={{
                                                            required: {
                                                                value: true,
                                                                message: t("form_error_required"),
                                                            },
                                                            pattern: {
                                                                value: /\b[0-9]{4,5}\b/,
                                                                message: t("company_health_postcode_pattern"),
                                                            },
                                                        }}
                                                        type="text"
                                                        name="postcode"
                                                        label={t("company_health_postcode_label")}
                                                        autocomplete="postal-code"
                                                        length={5}
                                                    />
                                                    <Input
                                                        control={control}
                                                        rules={{
                                                            required: {
                                                                value: true,
                                                                message: t("form_error_required"),
                                                            },
                                                        }}
                                                        type="text"
                                                        name="city"
                                                        label={t("company_health_city_label")}
                                                        autocomplete="address-level2"
                                                    />
                                                </FlexWrapper>
                                            </div>
                                            <Spacer medium />
                                            <span
                                                className={classNames(
                                                    FirmenGesundheitFormStyles.label,
                                                    FirmenGesundheitFormStyles.noMargin
                                                )}
                                            >
                                                <strong>{t("company_health_contact_person")}</strong>
                                            </span>
                                            <Spacer small />
                                            <Radio
                                                register={register("salutation", {
                                                    required: { value: true, message: t("form_error_required") },
                                                })}
                                                error={errors}
                                                label={t("partner_form_salutation_label")}
                                                flex
                                                options={genders.map(g => ({
                                                    label: t(g.name),
                                                    value: g.id,
                                                }))}
                                            />
                                            <FlexWrapper childWidths={width > 767 ? [50, 50] : [100, 100]} wrap>
                                                <Input
                                                    control={control}
                                                    rules={{
                                                        required: {
                                                            value: true,
                                                            message: t("form_error_required"),
                                                        },
                                                    }}
                                                    type="text"
                                                    name="firstname"
                                                    label={t("contact_form_firstname_label")}
                                                    autocomplete="given-name"
                                                />
                                                <Input
                                                    control={control}
                                                    rules={{
                                                        required: {
                                                            value: true,
                                                            message: t("form_error_required"),
                                                        },
                                                    }}
                                                    type="text"
                                                    name="lastname"
                                                    label={t("contact_form_lastname_label")}
                                                    autocomplete="family-name"
                                                />
                                            </FlexWrapper>
                                            <div>
                                                <Input
                                                    control={control}
                                                    rules={{
                                                        required: {
                                                            value: true,
                                                            message: t("form_error_required"),
                                                        },
                                                    }}
                                                    type="tel"
                                                    name="phone"
                                                    label={t("partner_form_phone_label")}
                                                />
                                                <Input
                                                    control={control}
                                                    rules={{
                                                        required: {
                                                            value: true,
                                                            message: t("form_error_required"),
                                                        },
                                                    }}
                                                    type="email"
                                                    name="email"
                                                    label={t("contact_form_email_label")}
                                                />
                                                <Input
                                                    control={control}
                                                    rules={{
                                                        required: {
                                                            value: true,
                                                            message: t("form_error_required"),
                                                        },
                                                    }}
                                                    type="text"
                                                    name="recognized"
                                                    label={t("company_health_recognized")}
                                                />
                                                <small className={FirmenGesundheitFormStyles.small}>
                                                    {t("company_health_legal_text")}
                                                </small>
                                                <Spacer small />
                                                <LinkButtonWrapper center>
                                                    <IconAction
                                                        title={t("company_health_icon_action_send")}
                                                        button
                                                        submit
                                                    />
                                                </LinkButtonWrapper>
                                            </div>
                                        </>
                                    )}
                                </form>
                            }
                            onClick={() => setActive(1)}
                        />
                    </div>
                    <div className={FirmenGesundheitFormStyles.imageWrapper}>
                        <LazyImage
                            url="assets"
                            path={"/lavita.de/bgm/lavita-kostenlose-flasche_tiny.png"}
                            srcSet={[{ w: 272, default: true }]}
                            sizes="(max-width: 767px) 172px, 272px"
                        />
                    </div>
                </div>
            ) : (
                <div className={FirmenGesundheitFormStyles.success} id="success_message">
                    <div className={FirmenGesundheitFormStyles.circle}>
                        <i className="lavita-icon"></i>
                    </div>
                    <div className={FirmenGesundheitFormStyles.successText}>
                        {active === 1 ? (
                            t("company_health_success")
                        ) : (
                            <div className={FirmenGesundheitFormStyles.appointment}>
                                <ReactMarkdown>{t("company_health_calendly_text")}</ReactMarkdown>
                            </div>
                        )}
                    </div>
                </div>
            )}
        </div>
    )
}

export default FirmenGesundheitForm
