import { Background, BackgroundColor, Container, Spacer, SpacerVariant } from "../components/layout"
import { Repeater, types } from "react-bricks/frontend"

import React from "react"

const Smallprint: types.Brick = () => {
    return (
        <Background color={BackgroundColor.Grey}>
            <Spacer variant={SpacerVariant.Small} />
            <Container>
                <Repeater propName="repeater" />
            </Container>
            <Spacer variant={SpacerVariant.Small} />
        </Background>
    )
}

Smallprint.schema = {
    name: "Smallprint",
    label: "Smallprint",
    repeaterItems: [
        {
            name: "repeater",
            items: [
                {
                    type: "SmallprintItem",
                    label: "Smallprint Item",
                    min: 1,
                },
            ],
        },
    ],
}

export default Smallprint
