import { Background, BackgroundColor, Container, Spacer } from "../components/layout"

import AccountDeletionForm from "../../components-v2/new/form/account-deletion"
import BiathlonForm from "../../components-v2/new/form/biathlon"
import Contact from "../../components/forms/contact"
import Coupon from "../../components-v2/new/form/coupon"
import EdelstahlFlasche from "../../components-v2/new/form/edelstahl-flasche"
import FirmenGesundheitForm from "../../components-v2/new/form/firmengesundheit"
import React from "react"
import { types } from "react-bricks/frontend"

const Forms = {
    empty: null,
    contact: (
        <Container>
            <Contact />
        </Container>
    ),
    voucher: (
        <Container>
            <Coupon />
        </Container>
    ),
    inlineVoucher: <Coupon inline />,
    biathlon: (
        <Background color={BackgroundColor.Grey}>
            <Spacer />
            <Container>
                <BiathlonForm />
            </Container>
            <Spacer />
        </Background>
    ),
    company_health: (
        <>
            <Background color={BackgroundColor.Grey}>
                <Container>
                    <FirmenGesundheitForm />
                </Container>
            </Background>
        </>
    ),
    stainless_steel_bottle: (
        <Background color={BackgroundColor.Grey}>
            <Spacer />
            <Container>
                <EdelstahlFlasche />
            </Container>
            <Spacer />
        </Background>
    ),
    account_deletion: (
        <Background color={BackgroundColor.Grey}>
            <Spacer />
            <Container>
                <AccountDeletionForm />
            </Container>
            <Spacer />
        </Background>
    ),
}

interface FormProps {
    form: keyof typeof Forms
    index?: number
}

const Form: types.Brick<FormProps> = ({ form, index }) => {
    return <div key={index}>{Forms[form] || <Spacer />}</div>
}

Form.schema = {
    name: "Forms",
    label: "Forms",
    sideEditProps: [
        {
            name: "form",
            label: "Form",
            type: types.SideEditPropType.Select,
            selectOptions: {
                display: types.OptionsDisplay.Select,
                options: [
                    { value: "empty", label: "Choose a form" },
                    { value: "contact", label: "Contact Form" },
                    { value: "voucher", label: "Voucher Form" },
                    { value: "inlineVoucher", label: "Inline Voucher Form" },
                    { value: "biathlon", label: "Biathlon Form" },
                    { value: "company_health", label: "Company Health Form" },
                    { value: "stainless_steel_bottle", label: "Stainless Steel Bottle Form" },
                    { value: "account_deletion", label: "Account Deletion Form" },
                ],
            },
        },
    ],
}

export default Form
