import { closeOverlay, useOverlayDispatch } from "../../shared/context/overlay"

import IconAction from "../../components/universal/icon-action"
import React from "react"
import Text from "../../components/universal/text"
import WhatsappOverlayStyles from "./whatsapp.module.css"
import { useTranslation } from "react-i18next"

const WhatsappOverlay = () => {
    const { t } = useTranslation("translations")

    const overlayDispatch = useOverlayDispatch()

    return (
        <div className={WhatsappOverlayStyles.block}>
            <Text bigger source={t("whatsapp_overlay_text")} />
            <IconAction
                path={`/${t("whatsapp_overlay_path")}`}
                icon="a"
                text={t("whatsapp_overlay_link")}
                trackGA={() => closeOverlay(overlayDispatch)}
            />
        </div>
    )
}

export default WhatsappOverlay
