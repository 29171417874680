import { Align, Color, MaxWidth, TypographyAlign, TypographyColor, TypographyMaxWidth } from "../components/typography"
import { Repeater, types } from "react-bricks/frontend"
import { Spacer, SpacerVariant } from "../components/layout"
import Typography, { TypographyElement } from "../Typography"

import React from "react"
import { TextLinkBrickProps } from "./TextLink"
import { TextLinkColor } from "../components/text"

export interface CenterTextProps {
    text: TypographyElement[]
    color?: TypographyColor
    spacerBottom?: SpacerVariant
    index?: number
}

const CenterText: types.Brick<CenterTextProps> = ({ color, index, spacerBottom }) => {
    return (
        <div key={index}>
            <Color color={color}>
                <Align align={TypographyAlign.Center}>
                    <MaxWidth mdMaxWidth={TypographyMaxWidth["70%"]} className="space-y-7 md:space-y-10">
                        <Typography allowList={["h3", "small", "sup", "link", "bold"]} />
                        <Repeater
                            propName="repeater"
                            renderWrapper={children => (
                                <div className="flex justify-center space-x-2 md:space-x-2.5">{children}</div>
                            )}
                            itemProps={
                                {
                                    color: color === TypographyColor.White ? TextLinkColor.White : TextLinkColor.Blue,
                                } as TextLinkBrickProps
                            }
                        />
                    </MaxWidth>
                </Align>
            </Color>
            <Spacer variant={spacerBottom || SpacerVariant.Small} />
        </div>
    )
}

CenterText.schema = {
    name: "CenterText",
    label: "Center Text",
    hideFromAddMenu: true,
    repeaterItems: [
        {
            name: "repeater",
            items: [
                {
                    type: "TextLink",
                    label: "Text Link",
                    max: 1,
                },
                {
                    type: "ImageButton",
                    label: "ImageButton",
                    max: 2,
                },
            ],
        },
    ],
    getDefaultProps: (): CenterTextProps => ({
        text: [
            {
                type: "h3",
                children: [
                    {
                        text: "Lorem ipsum dolor sit amet",
                    },
                ],
            },
            {
                type: "paragraph",
                children: [
                    {
                        text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc placerat sagittis faucibus.",
                    },
                ],
            },
        ],
    }),
}

export default CenterText
