import React, { ReactNode } from "react"
import RoundedBackgroundContainer, {
    ContainerBackgroundColor,
    ContainerVariant,
} from "../layout/rounded-background-container"

import Icon from "./icon"
import { Pictograms } from "../../../types/icons"

interface ContainerProps {
    children: ReactNode
    icon: Pictograms
    background?: ContainerBackgroundColor
    variant?: ContainerVariant
}

const Container = ({
    icon,
    children,
    background = ContainerBackgroundColor.Grey,
    variant = ContainerVariant.Base,
}: ContainerProps) => {
    return (
        <RoundedBackgroundContainer variant={variant} background={background} className="flex h-full flex-col">
            <Icon icon={icon} />
            {children}
        </RoundedBackgroundContainer>
    )
}

export default Container
