import { RefObject, useEffect, useState } from "react"
import { useDebounceCallback, useIsomorphicLayoutEffect } from "usehooks-ts"

import { useAdminContext } from "react-bricks"

type ScrollSize<T extends number | undefined = number | undefined> = {
    width: T
    height: T
}

type UseScrollSizeOptions<InitializeWithValue extends boolean | undefined> = {
    ref: RefObject<HTMLElement>
    initializeWithValue: InitializeWithValue
    debounceDelay?: number
}

const IS_SERVER = typeof window === "undefined"

// SSR version of useScrollSize.
export function useScrollSize(options: UseScrollSizeOptions<false>): ScrollSize
// CSR version of useScrollSize.
export function useScrollSize(options?: Partial<UseScrollSizeOptions<true>>): ScrollSize<number>
export function useScrollSize(options: Partial<UseScrollSizeOptions<boolean>> = {}): ScrollSize | ScrollSize<number> {
    let { ref, initializeWithValue = true } = options
    if (IS_SERVER) {
        initializeWithValue = false
    }

    const { isAdmin } = useAdminContext()

    const [scrollSize, setScrollSize] = useState<ScrollSize>(() => {
        if (initializeWithValue && ref?.current) {
            return {
                width: ref.current.scrollWidth,
                height: ref.current.scrollHeight,
            }
        }
        return {
            width: undefined,
            height: undefined,
        }
    })

    const debouncedSetScrollSize = useDebounceCallback(setScrollSize, options.debounceDelay)

    const adminFrameDocument = !IS_SERVER ? (document.getElementById("rb-admin-frame") as HTMLIFrameElement | null)?.contentWindow
        ?.document : undefined
    const adminContentFrameWindow = (adminFrameDocument?.getElementById("rb-content-frame") as HTMLIFrameElement | null)
        ?.contentWindow?.window

    function handleSize() {
        if (!ref?.current) return

        const setSize = options.debounceDelay ? debouncedSetScrollSize : setScrollSize

        setSize({
            width: ref.current.scrollWidth,
            height: ref.current.scrollHeight,
        })
    }

    useEffect(() => {
        // Define the listening target
        const targetElement = isAdmin && adminContentFrameWindow ? adminContentFrameWindow : window

        if (!(targetElement && targetElement.addEventListener)) return

        targetElement.addEventListener("resize", handleSize)

        // Remove event listener on cleanup
        return () => {
            targetElement.removeEventListener("resize", handleSize)
        }
    }, [isAdmin])

    // Set size at the first client-side load
    useIsomorphicLayoutEffect(() => {
        handleSize()
    }, [])

    return scrollSize
}
