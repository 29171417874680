import ImageEditor from "../Image"
import { Link } from "react-bricks/frontend"
import React from "react"
import { types } from "react-bricks/frontend"

export interface ImageButtonProps {
    href: string
    index?: number
}

const ImageButton: types.Brick<ImageButtonProps> = ({ index, href }) => {
    return (
        <div key={index}>
            <Link href={href}>
                <ImageEditor
                    maxWidth={200}
                    className="block h-[37px] w-auto sm:h-[43px] md:h-[57px]"
                />
            </Link>
        </div>
    )
}

ImageButton.schema = {
    name: "ImageButton",
    label: "Image Button",
    hideFromAddMenu: true,
    sideEditProps: [
        {
            name: "href",
            label: "Link",
            type: types.SideEditPropType.Text,
        },
    ],
}

export default ImageButton
