import {
    Align,
    Color,
    MaxWidth,
    TypographyAlign,
    TypographyColor,
    TypographyMaxWidth,
    alignOptions,
    maxWidthOptions,
} from "../components/typography"
import { CloudflarePlayer, VideoButton } from "../components/video"
import { Container, Spacer, SpacerVariant } from "../components/layout"
import ImageEditor, { AspectRatio } from "../Image"
import React, { ReactNode, RefObject, useEffect, useRef, useState } from "react"
import { Repeater, Text, types } from "react-bricks/frontend"
import Typography, { TypographyElement } from "../Typography"

import { ButtonBrickProps } from "../button/Button"
import { ButtonStyle } from "../components/button"
import { StreamPlayerApi } from "@cloudflare/stream-react"
import classNames from "classnames"
import { options } from "../../types"
import { useCheckForEmptyText } from "../../hooks/use-check-for-empty-text"
import { useIntersectionObserver } from "usehooks-ts"

export enum HeroStyle {
    Positive = "positive",
    Negative = "negative",
}

interface HeroProps {
    video: boolean
    style: HeroStyle
    align: TypographyAlign
    maxWidth: TypographyMaxWidth
    text: TypographyElement[]
    headline: TypographyElement[]
    cloudflareVideoId?: string
    cloudflareVideoIdMobile?: string
    shadow: boolean
    badge: boolean
}

interface PlayerProps {
    video: boolean
    id?: string
    isIntersecting: boolean
    shadow: boolean
    image: ReactNode
    wrapper: (children: JSX.Element) => JSX.Element
    wrapperRef: RefObject<HTMLDivElement>
    className?: string
}

const Player = ({ video, id, isIntersecting, shadow, image, wrapper, wrapperRef, className }: PlayerProps) => {
    const player = useRef<StreamPlayerApi>()

    const [playing, setPlaying] = useState(true)
    const [progress, setProgress] = useState(0)

    const toggleVideo = () => {
        setPlaying(play => {
            if (!player.current) return !play

            if (play) {
                player.current.pause()
            } else {
                try {
                    player.current.play()
                } catch (e) {
                    player.current.muted = true
                    player.current.play()
                }
            }
            return !play
        })
    }

    const wrapperIntersectionObserver = useIntersectionObserver(wrapperRef, {
        rootMargin: "256px 256px 256px 256px",
    })

    useEffect(() => {
        if (!wrapperIntersectionObserver || !player.current) return

        if (!wrapperIntersectionObserver.isIntersecting) {
            player.current.pause()
        } else if (playing) {
            try {
                player.current.play()
            } catch (e) {
                player.current.muted = true
                player.current.play()
            }
        }
    }, [wrapperIntersectionObserver?.isIntersecting, playing])

    return (
        <>
            {wrapper(
                <>
                    {image}
                    {video && isIntersecting && (
                        <CloudflarePlayer
                            id={id}
                            autoplay
                            onProgress={played => setProgress(played)}
                            streamRef={player}
                        />
                    )}
                </>
            )}
            {video && (
                <>
                    {shadow && (
                        <>
                            <div
                                className={classNames(
                                    "to-transparent absolute top-0 h-[180px] w-full bg-gradient-to-b from-dark-grey sm:h-[200px] md:h-[250px] lg:h-[350px] xl:h-[270px]",
                                    className
                                )}
                            />
                            <div
                                className={classNames(
                                    "to-transparent absolute bottom-0 h-[180px] w-full bg-gradient-to-t from-dark-grey sm:h-[200px] md:h-[250px] lg:h-[350px] xl:h-[270px]",
                                    className
                                )}
                            />
                        </>
                    )}
                    <button
                        onClick={toggleVideo}
                        className={classNames(
                            "absolute bottom-[25px] right-[25px] z-2 sm:bottom-[70px] md:bottom-[90px] md:right-10 lg:bottom-[120px] lg:right-[60px] xl:bottom-[160px]",
                            className
                        )}
                        type="button"
                    >
                        <VideoButton playing={playing} progress={progress} />
                    </button>
                </>
            )}
        </>
    )
}

const Hero: types.Brick<HeroProps> = ({
    style,
    video,
    cloudflareVideoId,
    cloudflareVideoIdMobile,
    align,
    maxWidth,
    headline,
    shadow,
    badge,
}) => {
    const emptyHeadline = useCheckForEmptyText(headline)

    const mobileVideoRef = useRef<HTMLIFrameElement>(null)
    const videoRef = useRef<HTMLIFrameElement>(null)

    const mobileVideoEntry = useIntersectionObserver(mobileVideoRef, {
        rootMargin: "1256px 256px 1256px 256px",
        freezeOnceVisible: true,
    })
    const videoEntry = useIntersectionObserver(videoRef, {
        rootMargin: "1256px 256px 1256px 256px",
        freezeOnceVisible: true,
    })

    return (
        <div>
            <div className="relative">
                <div className="relative">
                    <Player
                        id={cloudflareVideoIdMobile}
                        isIntersecting={!!mobileVideoEntry?.isIntersecting}
                        shadow={shadow}
                        image={
                            <ImageEditor
                                aspectRatio={AspectRatio["4/5"]}
                                maxWidth={768}
                                className="w-full"
                                propName="image_mobile"
                            />
                        }
                        wrapper={children => (
                            <div className="aspect-h-5 aspect-w-4 w-full sm:hidden" ref={mobileVideoRef}>
                                {children}
                            </div>
                        )}
                        wrapperRef={mobileVideoRef}
                        video={video}
                        className="sm:hidden"
                    />
                    <Player
                        id={cloudflareVideoId}
                        isIntersecting={!!videoEntry?.isIntersecting}
                        shadow={shadow}
                        image={
                            <ImageEditor
                                aspectRatio={AspectRatio["16/9"]}
                                maxWidth={1920}
                                className="w-full"
                                propName="image"
                            />
                        }
                        wrapper={children => (
                            <div className="hidden sm:aspect-h-9 sm:aspect-w-16 sm:block" ref={videoRef}>
                                {children}
                            </div>
                        )}
                        wrapperRef={videoRef}
                        video={video}
                        className="hidden sm:block"
                    />
                </div>
                <div className="absolute top-1/2 w-full -translate-y-1/2 justify-center">
                    <Container>
                        <Align align={align}>
                            <MaxWidth
                                smMaxWidth={
                                    maxWidth === TypographyMaxWidth.Half
                                        ? TypographyMaxWidth["70%"]
                                        : maxWidth || TypographyMaxWidth["70%"]
                                }
                                mdMaxWidth={maxWidth || TypographyMaxWidth.Half}
                                lgMaxWidth={maxWidth || TypographyMaxWidth.Half}
                            >
                                <Color
                                    color={style === HeroStyle.Positive ? TypographyColor.Blue : TypographyColor.White}
                                >
                                    {badge && (
                                        <div className="mb-5 hidden w-full sm:mb-6 sm:flex">
                                            <Text
                                                propName="badgeHero"
                                                renderBlock={({ children }) => (
                                                    <span className="rounded-full bg-copperfield font-semibold text-white sm:h-12 sm:px-5 sm:py-3 sm:text-xl lg:h-[56px] lg:px-7 lg:text-2xl">
                                                        {children}
                                                    </span>
                                                )}
                                            />
                                        </div>
                                    )}
                                    {!emptyHeadline && (
                                        <div className="w-full sm:mb-5 lg:mb-7">
                                            <Typography
                                                allowList={["h1", "h3", "sup", "link", "seo-h1"]}
                                                propName="headline"
                                            />
                                        </div>
                                    )}
                                    <div className="hidden sm:block">
                                        <Typography
                                            allowList={["h1", "h3", "ul", "small", "sup", "link", "bold", "seo-h1"]}
                                            propName="text"
                                        />
                                    </div>
                                </Color>
                            </MaxWidth>
                            <div className="hidden sm:block">
                                <Repeater
                                    propName="repeater"
                                    renderWrapper={children => (
                                        <div
                                            className={classNames("mt-7 flex space-x-2 md:mt-10 md:space-x-2.5", {
                                                "justify-center": align === TypographyAlign.Center,
                                            })}
                                        >
                                            {children}
                                        </div>
                                    )}
                                />
                            </div>
                        </Align>
                    </Container>
                </div>
            </div>
            <div className="sm:hidden">
                <Container>
                    <Spacer variant={SpacerVariant.Small} />
                    <Align align={TypographyAlign.Left}>
                        <Color>
                            {badge && (
                                <div className="mb-5 flex">
                                    <Text
                                        propName="badgeHero"
                                        renderBlock={({ children }) => (
                                            <span className="h-[35px] rounded-full bg-copperfield px-3.5 py-2 text-md font-semibold text-white">
                                                {children}
                                            </span>
                                        )}
                                    />
                                </div>
                            )}
                            <Typography
                                allowList={["h1", "h3", "ul", "small", "sup", "link", "bold", "seo-h1"]}
                                propName="text"
                            />
                        </Color>
                    </Align>
                    <Align align={align}>
                        <Repeater
                            propName="repeater"
                            renderWrapper={children => (
                                <div className="mt-7 flex space-x-2 md:space-x-2.5">{children}</div>
                            )}
                            itemProps={{ style: ButtonStyle.Primary } as ButtonBrickProps}
                        />
                    </Align>
                    <Spacer variant={SpacerVariant.Small} />
                </Container>
            </div>
        </div>
    )
}

export const styleOptions = options<typeof HeroStyle>(HeroStyle)

Hero.schema = {
    name: "Hero",
    label: "Hero",
    repeaterItems: [
        {
            name: "repeater",
            items: [
                {
                    type: "Button",
                    label: "Button",
                    max: 1,
                },
                {
                    type: "ImageButton",
                    label: "ImageButton",
                    max: 2,
                },
            ],
        },
    ],
    sideEditProps: [
        {
            name: "align",
            label: "Align",
            type: types.SideEditPropType.Select,
            selectOptions: {
                options: alignOptions,
                display: types.OptionsDisplay.Select,
            },
        },
        {
            name: "maxWidth",
            label: "Max Width",
            type: types.SideEditPropType.Select,
            selectOptions: {
                options: maxWidthOptions,
                display: types.OptionsDisplay.Select,
            },
        },
        {
            name: "style",
            label: "Style",
            type: types.SideEditPropType.Select,
            selectOptions: {
                options: styleOptions,
                display: types.OptionsDisplay.Select,
            },
        },
        {
            name: "badge",
            label: "Badge",
            type: types.SideEditPropType.Boolean,
        },
        {
            name: "video",
            label: "Video",
            type: types.SideEditPropType.Boolean,
        },
        {
            name: "cloudflareVideoId",
            label: "Cloudflare Video Id",
            type: types.SideEditPropType.Text,
            show: props => props.video,
        },
        {
            name: "cloudflareVideoIdMobile",
            label: "Cloudflare Video Id Mobile",
            type: types.SideEditPropType.Text,
            show: props => props.video,
        },
        {
            name: "shadow",
            label: "Shadow",
            type: types.SideEditPropType.Boolean,
            show: props => props.video,
        },
    ],
    getDefaultProps: (): HeroProps => ({
        style: HeroStyle.Positive,
        align: TypographyAlign.Left,
        maxWidth: TypographyMaxWidth.Half,
        video: false,
        badge: false,
        shadow: true,
        headline: [
            {
                type: "h3",
                children: [
                    {
                        text: "Lorem ipsum dolor sit amet",
                    },
                ],
            },
        ],
        text: [
            {
                type: "paragraph",
                children: [
                    {
                        text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
                    },
                ],
            },
        ],
    }),
}

export default Hero
