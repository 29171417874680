import Image, { AspectRatio } from "../Image"
import { Repeater, types } from "react-bricks/frontend"
import Typography, { TypographyElement } from "../Typography"

import React from "react"

export interface TabContentItemProps {
    index?: number
    activeTab?: number
    text: TypographyElement[]
}

const TabContentItem: types.Brick<TabContentItemProps> = ({ index, activeTab }) => {
    if (activeTab !== index) return null

    return (
        <div
            key={index}
            className="mx-auto gap-10 grid grid-cols-1 sm:max-w-[680px] sm:grid-cols-2 md:max-w-[784px] md:gap-16 lg:max-w-[1020px] lg:grid-cols-[460px_480px] lg:gap-20"
        >
            <div className="space-y-7 sm:order-2 md:space-y-10 flex flex-col justify-center">
                <div className="text-center sm:text-left">
                    <Typography allowList={["h4", "ul", "small", "sup", "link", "bold"]} />
                </div>
                <Repeater
                    propName="repeater"
                    renderWrapper={children => <div className="flex flex-col space-y-5 sm:space-y-7">{children}</div>}
                />
            </div>
            <Image maxWidth={687} aspectRatio={AspectRatio["4/5"]} className="px-[15px] sm:order-1 sm:px-0" />
        </div>
    )
}

TabContentItem.schema = {
    name: "TabContentItem",
    label: "Tab Content Item",
    hideFromAddMenu: true,
    repeaterItems: [
        {
            name: "repeater",
            items: [
                {
                    type: "Button",
                    label: "Button",
                    max: 1,
                },
                {
                    type: "TextLink",
                    label: "Text Link",
                    max: 1,
                },
            ],
        },
    ],
    getDefaultProps: (): TabContentItemProps => ({
        text: [
            {
                type: "h4",
                children: [
                    {
                        text: "Lorem ipsum dolor sit amet, consetetur sadipscing.",
                        bold: true,
                    },
                ],
            },
            {
                type: "paragraph",
                children: [
                    {
                        text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc placerat sagittis faucibus.",
                    },
                ],
            },
        ],
    }),
}

export default TabContentItem
