import React, { ReactNode } from "react"

import classNames from "classnames"
import { options } from "../../../types/options"
import { twMerge } from "tailwind-merge"

export enum TypographyAlign {
    "Left" = "left",
    "Center" = "center",
}

export interface TypographyAlignProps {
    children: ReactNode
    align?: TypographyAlign
    className?: string
}

const Align = ({ children, align = TypographyAlign.Left, className }: TypographyAlignProps) => {
    return (
        <div
            className={twMerge(
                classNames({
                    "text-center child:mx-auto": align === TypographyAlign.Center,
                    "text-left child:mx-0": align === TypographyAlign.Left,
                }),
                className
            )}
        >
            {children}
        </div>
    )
}

export const alignOptions = options<typeof TypographyAlign>(TypographyAlign)

export default Align
