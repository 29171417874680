import ConditionalWrapper from "./conditional-wrapper"
import { Link } from "../../shared/utils/router"
import React from "react"
import ReactMarkdown from "react-markdown"
import TextStyles from "./text.module.css"
import classNames from "classnames"
import rehypeRaw from "rehype-raw"
import remarkGfm from "remark-gfm"
import { scrollTo } from "../../shared/utils/smooth-scroll"

const LinkRenderer = props => {
    const anchor = (e, id) => {
        e.preventDefault()
        scrollTo(id)
    }
    if (props.href.indexOf("#") === 0) {
        return (
            <sup>
                <Link
                    to={props.href}
                    className={TextStyles.footnote}
                    onClick={e => anchor(e, props.href.replace("#", ""))}
                >
                    {props.children}
                </Link>
            </sup>
        )
    } else if (props.href.indexOf(process.env.REPLACE_MENU_PART_WEB) === 0) {
        return <Link to={props.href.replace(process.env.REPLACE_MENU_PART_WEB, "")}>{props.children}</Link>
    } else {
        return <a href={props.href}>{props.children}</a>
    }
}

const Text = props => {
    const onSubmit = event => {
        event.preventDefault()
        props.button()
    }

    return (
        <div
            className={classNames(TextStyles.block, {
                [TextStyles.small]: props.small,
                [TextStyles.allSmall]: props.allSmall,
                [TextStyles.medium]: props.medium,
                [TextStyles.big]: props.big,
                [TextStyles.bigMedium]: props.bigMedium,
                [TextStyles.bigger]: props.bigger,
                [TextStyles.mw1080]: props.maxWidth === 1080,
                [TextStyles.mw1020]: props.maxWidth === 1020,
                [TextStyles.mw890]: props.maxWidth === 890,
                [TextStyles.mw860]: props.maxWidth === 860,
                [TextStyles.mw708]: props.maxWidth === 708,
                [TextStyles.mw608]: props.maxWidth === 608,
                [TextStyles.mw520]: props.maxWidth === 520,
                [TextStyles.center]: props.center,
                [TextStyles.centerOnlyDesktop]: props.centerOnlyDesktop,
                [TextStyles.listGrid]: props.listGrid,
                [TextStyles.inlineStrong]: props.inlineStrong,
                [TextStyles.white]: props.white,
                [TextStyles.whiteDesktop]: props.whiteDesktop,
                [TextStyles.whiteTablet]: props.whiteTablet,
                [TextStyles.blue]: props.blue,
                [TextStyles.lightBlue]: props.lightBlue,
                [TextStyles.transparent]: props.transparent,
                [TextStyles.lightBlueTablet]: props.lightBlueTablet,
                [TextStyles.bigMediumSmall]: props.bigMediumSmall,
                [TextStyles.blueShade]: props.blueShade,
                [TextStyles.lessFontWeight]: props.lessFontWeight,
                [TextStyles.headline40]: props.headline40,
                [TextStyles.noMargin]: props.noMargin,
                [TextStyles.leafList]: props.leafList,
            })}
        >
            <ConditionalWrapper
                condition={props.button}
                wrapper={children => (
                    <form noValidate onSubmit={onSubmit}>
                        {children}
                    </form>
                )}
            >
                {props.number && <div className={TextStyles.number}>{props.number}.</div>}
                <ReactMarkdown
                    rehypePlugins={[rehypeRaw]}
                    remarkPlugins={[remarkGfm]}
                    components={{ a: ({ node, ...props }) => <LinkRenderer {...props} /> }}
                >
                    {props.source}
                </ReactMarkdown>
            </ConditionalWrapper>
        </div>
    )
}

export default Text
