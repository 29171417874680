import {
    Align,
    Color,
    MaxWidth,
    TypographyAlign,
    TypographyColor,
    TypographyMaxWidth,
    alignOptions,
} from "../components/typography"
import { Background, BackgroundColor, Container, Spacer, SpacerVariant, backgroundOptions } from "../components/layout"
import { Repeater, types } from "react-bricks/frontend"
import Typography, { TypographyElement } from "../Typography"

import React from "react"
import { TextLinkBrickProps } from "./TextLink"
import { TextLinkColor } from "../components/text"

interface BasicTextProps {
    background: { color: BackgroundColor } | BackgroundColor
    align: TypographyAlign
    text: TypographyElement[]
    spacerTop: boolean
    spacerBottom: boolean
    id?: string
}

const BasicText: types.Brick<BasicTextProps> = ({ background, align, spacerTop, spacerBottom, id }) => {
    const bgColor = typeof background === "object" ? background.color : background

    return (
        <Background color={bgColor}>
            <Spacer variant={!spacerTop ? SpacerVariant.None : undefined} />
            <Container>
                <Color color={bgColor === BackgroundColor.Green ? TypographyColor.White : TypographyColor.Darkgrey}>
                    <Align align={align}>
                        <MaxWidth lgMaxWidth={TypographyMaxWidth["70%"]} className="space-y-7 md:space-y-10">
                            <Typography
                                id={id}
                                allowList={["h1", "h2", "h3", "h4", "ul", "small", "sup", "link", "bold", "italic", "quote"]}
                            />
                            <Repeater
                                propName="repeater"
                                itemProps={
                                    {
                                        color:
                                            bgColor === BackgroundColor.Green
                                                ? TextLinkColor.White
                                                : TextLinkColor.Blue,
                                    } as TextLinkBrickProps
                                }
                            />
                        </MaxWidth>
                    </Align>
                </Color>
            </Container>
            <Spacer variant={!spacerBottom ? SpacerVariant.None : undefined} />
        </Background>
    )
}

BasicText.schema = {
    name: "BasicText",
    label: "Basic Text",
    repeaterItems: [
        {
            name: "repeater",
            items: [
                {
                    type: "Button",
                    label: "Button",
                    max: 1,
                },
                {
                    type: "TextLink",
                    label: "Text Link",
                    max: 1,
                },
            ],
        },
    ],
    sideEditProps: [
        {
            name: "id",
            label: "ID",
            type: types.SideEditPropType.Text,
        },
        {
            name: "align",
            label: "Align",
            type: types.SideEditPropType.Select,
            selectOptions: {
                options: alignOptions,
                display: types.OptionsDisplay.Select,
            },
        },
        {
            name: "background",
            label: "Background",
            type: types.SideEditPropType.Select,
            selectOptions: {
                options: backgroundOptions,
                display: types.OptionsDisplay.Color,
            },
        },
        {
            groupName: "Spacer",
            props: [
                {
                    name: "spacerTop",
                    label: "Spacer Top",
                    type: types.SideEditPropType.Boolean,
                },
                {
                    name: "spacerBottom",
                    label: "Spacer Bottom",
                    type: types.SideEditPropType.Boolean,
                },
            ],
        },
    ],
    getDefaultProps: (): BasicTextProps => ({
        align: TypographyAlign.Left,
        background: { color: BackgroundColor.White },
        text: [
            {
                type: "h1",
                children: [
                    {
                        text: "Lorem ipsum dolor sit amet",
                    },
                ],
            },
            {
                type: "paragraph",
                children: [
                    {
                        text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc placerat sagittis faucibus.",
                    },
                ],
            },
        ],
        spacerTop: true,
        spacerBottom: true,
    }),
}

export default BasicText
