import { Background, BackgroundColor, Container, backgroundOptions } from "../components/layout"
import { Repeater, types } from "react-bricks/frontend"

import { ConditionalWrapper } from "../components/util"
import React from "react"
import { StripeIconTextProps } from "./StripeIconText"
import classNames from "classnames"
import { twMerge } from "tailwind-merge"
import { useLocalesState } from "../../shared/context/locales"
import { useQuery } from "@tanstack/react-query"
import { useTranslation } from "react-i18next"

interface TrustStripeBrickProps {
    overlap: boolean
    background?: { color: BackgroundColor } | BackgroundColor
    repeater?: {
        id: string
        props: StripeIconTextProps
        type: "TrustStripeIconText"
    }[]
}

const TrustStripeBrick: types.Brick<TrustStripeBrickProps> = ({ overlap, background, repeater }) => {
    const { t } = useTranslation("translations")
    const { language, region } = useLocalesState()

    const { data: trustedShopsData } = useQuery(
        ["getTrustedShops", { id: "chl-a651571a-c49f-4212-8f4a-bfba73bcf931" }],
        () =>
            fetch(
                "https://integrations.etrusted.com/feeds/grades/v1/channels/chl-a651571a-c49f-4212-8f4a-bfba73bcf931/touchpoints/all/feed.json"
            ).then(d => d.json())
    )

    const rating = trustedShopsData?.grades["365days"].rating
    const count = trustedShopsData?.grades.overall.count

    const bgColor = typeof background === "object" ? background.color : background

    return (
        <ConditionalWrapper
            condition={overlap}
            wrapper={children => <Background color={bgColor}>{children}</Background>}
        >
            <Container>
                <ConditionalWrapper
                    condition={overlap}
                    wrapper={children => (
                        <div className="relative pb-10 sm:pb-[68px] md:pb-[54px] lg:pb-[64px] xl:pb-[74px]">
                            <div className="z-2 flex items-center justify-center rounded-base bg-white shadow-base sm:absolute sm:w-full sm:-translate-y-[30px] sm:rounded-lg md:-translate-y-1/2 lg:shadow-lg">
                                {children}
                            </div>
                        </div>
                    )}
                >
                    <div
                        className={twMerge(
                            classNames(
                                "box-content grid grow grid-cols-1 gap-y-10 p-5 sm:justify-center sm:p-3.5 xl:p-6",
                                { "sm:grid-cols-3": repeater?.length === 2 },
                                { "sm:grid-cols-2": repeater?.length === 1 }
                            )
                        )}
                    >
                        <Repeater propName="repeater" />
                        {trustedShopsData && (
                            <div className="flex items-center justify-center sm:px-3 sm:pr-0 first:sm:px-0 md:pl-7 lg:pl-10 xl:pl-16 xl:pr-10">
                                <a
                                    href="https://www.trustedshops.de/bewertung/info_X49E710FC236D0FD6604F8259314C8A7F.html"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="group flex flex-wrap items-center justify-center gap-x-3 gap-y-2 text-base text-dark-grey"
                                >
                                    <div className="flex items-center justify-center gap-x-3">
                                        <div className="flex gap-[0.195em]">
                                            {[...Array(Math.floor(rating))].map((_, i) => (
                                                <div className="h-4 w-4" key={i}>
                                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 20">
                                                        <path
                                                            fillRule="evenodd"
                                                            clipRule="evenodd"
                                                            d="M10.623 0 8.115 7.637 0 7.64l6.566 4.72L4.06 20l6.562-4.72L17.191 20l-2.507-7.64 6.566-4.72-8.115-.003L10.623 0Z"
                                                            fill="#FFDC0F"
                                                        />
                                                    </svg>
                                                </div>
                                            ))}
                                            {rating !== 5 && (
                                                <div className="relative h-4 w-4">
                                                    <div className="absolute h-full w-full">
                                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 20">
                                                            <path
                                                                fillRule="evenodd"
                                                                clipRule="evenodd"
                                                                d="M10.623 0 8.115 7.637 0 7.64l6.566 4.72L4.06 20l6.562-4.72L17.191 20l-2.507-7.64 6.566-4.72-8.115-.003L10.623 0Z"
                                                                fill="#CCC"
                                                            />
                                                        </svg>
                                                    </div>
                                                    <div
                                                        className="absolute h-full overflow-hidden"
                                                        style={{ width: (rating % 1) * 100 + "%" }}
                                                    >
                                                        <svg
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            viewBox="0 0 22 20"
                                                            className="w-4"
                                                        >
                                                            <path
                                                                fillRule="evenodd"
                                                                clipRule="evenodd"
                                                                d="M10.623 0 8.115 7.637 0 7.64l6.566 4.72L4.06 20l6.562-4.72L17.191 20l-2.507-7.64 6.566-4.72-8.115-.003L10.623 0Z"
                                                                fill="#FFDC0F"
                                                            />
                                                        </svg>
                                                    </div>
                                                </div>
                                            )}
                                            {[...Array(5 - Math.floor(rating) - 1)].map((_, i) => (
                                                <div className="h-4 w-4" key={i}>
                                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 20">
                                                        <path
                                                            fillRule="evenodd"
                                                            clipRule="evenodd"
                                                            d="M10.623 0 8.115 7.637 0 7.64l6.566 4.72L4.06 20l6.562-4.72L17.191 20l-2.507-7.64 6.566-4.72-8.115-.003L10.623 0Z"
                                                            fill="#CCC"
                                                        />
                                                    </svg>
                                                </div>
                                            ))}
                                        </div>
                                        <div className="font-bold group-hover:text-blue">
                                            {rating.toLocaleString(language)}
                                        </div>
                                        <div className="group-hover:text-blue">
                                            {t(
                                                rating < 1.5
                                                    ? "truststripe_very_poor"
                                                    : rating < 2.5
                                                    ? "truststripe_poor"
                                                    : rating < 3.5
                                                    ? "truststripe_fair"
                                                    : rating < 4.5
                                                    ? "truststripe_good"
                                                    : "truststripe_excellent"
                                            )}
                                        </div>
                                    </div>
                                    <div className="flex items-center justify-center gap-x-3">
                                        <div className="text-light-grey group-hover:text-blue">
                                            {t("truststripe_count", {
                                                count: count,
                                                countString: count.toLocaleString(language),
                                            })}
                                            {region !== "DE" && t("truststripe_in_germany")}
                                        </div>
                                        <div className="w-[26px]">
                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36 36">
                                                <path
                                                    fillRule="evenodd"
                                                    clipRule="evenodd"
                                                    d="M6.481 17.89c0 6.238 5.057 11.294 11.295 11.294 6.237 0 11.294-5.056 11.294-11.294S24.013 6.596 17.776 6.596c-6.238 0-11.295 5.056-11.295 11.294ZM0 17.947C0 8.047 8.025.024 17.924.024c9.9 0 17.924 8.024 17.924 17.923 0 9.9-8.025 17.925-17.924 17.925C8.024 35.872 0 27.847 0 17.947Zm20.192-6.69c.857 0 1.322.644 1.322 1.465 0 2.822-3.322 4.785-5.714 5.499.5-1.964 1.821-6.963 4.392-6.963Zm-3.963 14.784c3.07 0 5.356-2.215 6.999-4.642l-.894-.679c-1.178 1.428-2.784 3.357-4.785 3.357-1.535 0-2.284-1.287-2.284-2.678 0-.679.107-1.357.214-2.035l.498-.125c3.236-.806 8.928-2.223 8.928-6.052 0-2.25-2.499-3-4.356-3-5.535 0-9.462 5.213-9.462 10.533 0 3.285 1.892 5.32 5.142 5.32Z"
                                                    fill="#000"
                                                />
                                            </svg>
                                        </div>
                                    </div>
                                </a>
                            </div>
                        )}
                    </div>
                </ConditionalWrapper>
            </Container>
        </ConditionalWrapper>
    )
}

TrustStripeBrick.schema = {
    name: "TrustStripe2",
    label: "Trust Stripe",
    repeaterItems: [
        {
            name: "repeater",
            items: [
                {
                    type: "TrustStripeIconText",
                    label: "Icon Text",
                    max: 2,
                    min: 0,
                },
            ],
        },
    ],
    sideEditProps: [
        {
            name: "overlap",
            label: "Overlap",
            type: types.SideEditPropType.Boolean,
        },
        {
            name: "background",
            label: "Background",
            type: types.SideEditPropType.Select,
            selectOptions: {
                options: backgroundOptions,
                display: types.OptionsDisplay.Color,
            },
            show: props => props.overlap,
        },
    ],
    getDefaultProps: (): TrustStripeBrickProps => ({
        overlap: false,
        background: { color: BackgroundColor.White },
    }),
}

export default TrustStripeBrick
