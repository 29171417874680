import { Button, ButtonStyle, buttonStyleOptions } from "../components/button"
import { Icons, iconOptions, pictogramOptions } from "../../types"

import React from "react"
import { TypographyAlign } from "../components/typography"
import { types } from "react-bricks/frontend"

export interface ButtonBrickProps {
    href: string
    title: string
    style: ButtonStyle
    showIcon: boolean
    icon?: Icons
    index?: number
    target?: string
    align?: TypographyAlign
}

const ButtonBrick: types.Brick<ButtonBrickProps> = ({ href, target, title, style, index, icon, showIcon }) => {
    return (
        <div key={index} className="w-full sm:w-auto sm:inline-block">
            <Button
                href={href}
                target={target}
                title={title}
                style={style}
                icon={showIcon ? icon : undefined}
            />
        </div>
    )
}

ButtonBrick.schema = {
    name: "Button",
    label: "Button",
    hideFromAddMenu: true,
    sideEditProps: [
        {
            name: "href",
            label: "Button Link",
            type: types.SideEditPropType.Text,
        },
        {
            name: "target",
            label: "Target",
            type: types.SideEditPropType.Select,
            selectOptions: {
                options: [
                    {
                        value: "_self",
                        label: "Self",
                    },
                    {
                        value: "_blank",
                        label: "Blank",
                    },
                ],
                display: types.OptionsDisplay.Select,
            },
        },
        {
            name: "title",
            label: "Title",
            type: types.SideEditPropType.Text,
        },
        {
            name: "showIcon",
            label: "Show Icon",
            type: types.SideEditPropType.Boolean,
        },
        {
            name: "icon",
            label: "Icon",
            type: types.SideEditPropType.Select,
            selectOptions: {
                options: [...pictogramOptions, ...iconOptions],
                display: types.OptionsDisplay.Select,
            },
            show: props => props.showIcon,
        },
        {
            name: "style",
            label: "Style",
            type: types.SideEditPropType.Select,
            selectOptions: {
                options: buttonStyleOptions,
                display: types.OptionsDisplay.Select,
            },
        },
    ],
    getDefaultProps: (): ButtonBrickProps => ({
        href: process.env.SITE_URL,
        title: "LaVita",
        icon: Icons.cart,
        showIcon: true,
        style: ButtonStyle.Primary,
    }),
}

export default ButtonBrick
