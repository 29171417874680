import { options } from "./options"

export enum Pictograms {
    vitality = "",
    cell = "",
    balance = "",
    metabolism = "",
    power2 = "",
    thyroid = "",
    thyroid2 = "",
    tummy = "",
    power = "",
    concentration = "",
    harmony = "",
    concentration2 = "",
    bones = "",
    immune2 = "",
    immune = "",
    hormones = "",
    cardio = "",
    hair = "",
    energy2 = "",
    energy = "",
    bloodsugar = "",
    fertility = "",
    micro = "",
    natural = "",
    fruits = "",
    science = "",
    fruits_vegetables = "",
    vegetables = "",
    herbs = "",
    oils = "",
    fibre = "",
    service2 = "",
    package1 = "",
    package2 = "",
    package3 = "",
    fridge = "",
    cup = "",
    calendar = "",
    mobilephone_thin = "",
    best_before = "",
    study = "",
    cap_thin = "",
    coins = "",
    thumbsup = "",
    nocommitment_thin = "",
    delivery_thin = "",
    duration_thin = "",
    box_thin = "",
    international = "",
    mail_thin = "",
    lovemail_thin = "",
    lovemail2_thin = "",
    phone_thin = "",
    world_thin = "",
    bag_thin = "",
    maps_thin = "",
    service_thin = "",
    question = "",
    bell = "",
    light_bulb = "",
    hourglass = "",
    pills1 = "",
    pills2 = "",
    group = "",
    handshake = "",
    docteam = "",
    advertising = "",
    quest_bubble = "",
    woman = "",
    man = "",
    smoke = "",
    sport = "",
    teddy_thin = "",
    flower = "",
    clock_thin = "",
    feather = "",
    key_thin = "",
    tools = "",
    yoga = "",
    hands = "",
    target_thin = "",
    oil_bottle = "",
    fast_clock = "",
    calendar01 = "",
    calendar02 = "",
    calendar03 = "",
    baby = "",
    anchor = "",
    oil_bottle2 = "",
    piggy_bank = "",
    nr6 = "",
    nr5 = "",
    nr4 = "",
    nr3 = "",
    nr2 = "",
    nr1 = "",
    check2 = "",
    lung = "",
    barrel = "",
    street_sign = "",
    walking_stick = "",
    christmas_tree = "",
    reload_thin = "",
    email_at = "",
    broken_arrow1 = "",
    broken_arrow2 = "",
    booklets = "",
    tape_measure = "",
    doctor = "",
    hallo = "",
    pregnant = "",
    mask = "",
    patient = "",
    info_thin = "",
    checklist_thin = "",
    b_fruits_vegetables = "",
    b_herbs = "",
    b_natural = "",
    b_oils = "",
    b_fibre = "",
    b_micro = "",
    chef_hat = "",
    book = "",
    fridge_thin = "",
    burger = "",
    spoon = "",
    two_spoon = "",
    cart_thin_v2 = "",
    euro_thin = "",
    cart_thin = "",
    bill_thin = "",
    quote = "",
    quote_outline = "",
    key_drop = "",
    food_safe = "",
    travel_bag = "",
    measuring_cup = "",
    plane = "",
    product_lavita500 = "",
    product_lavita210 = "",
    product_lavita_starter = "",
    product_lavita_7plus1 = "",
    product_lavita_giftbox = "",
    product_lavita_subscription_circle = "",
    product_lavita_subscription = "",
    product_lavita_subscription_outline = "",
    product_lavita_500_outline = "",
    product_lavita_500_outline_3d = "",
    preparation_step1 = "",
    preparation_step2 = "",
    preparation_step3 = "",
    spoon_glass = "",
    spoon_glass_mix = "",
    bottle_glass = "",
    product_lavita_3 = "",
}

export enum Icons {
    link = "",
    overlay = "",
    play_link = "",
    close = "",
    info = "",
    circle_quest = "",
    clock = "",
    timer = "",
    less = "",
    more = "",
    circle_check = "",
    circle_user = "",
    key = "",
    cart = "",
    heart = "",
    shop = "",
    profile = "",
    print = "",
    reload = "",
    checklist = "",
    map1 = "",
    map2 = "",
    post = "",
    phone = "",
    date = "",
    edit = "",
    view = "",
    logistic = "",
    filter = "",
    card = "",
    home = "",
    dash = "",
    sl_outline = "",
    service = "",
    logout = "",
    star = "",
    bill = "",
    edit_user = "",
    delete = "",
    plus_thin = "",
    plus = "",
    arrow_down = "",
    arrow_down_thin = "",
    arrow_right2 = "",
    arrow_bold = "",
    arrow_left = "",
    arrow_right = "",
    minus = "",
    news = "",
    mail = "",
    download = "",
    next = "",
    box = "",
    arrow = "",
    settings = "",
    search1 = "",
    search2 = "",
    pdf = "",
    lovemail1 = "",
    lovemail2 = "",
    nocommitment = "",
    job = "",
    folder = "",
    media = "",
    mobilephone = "",
    check = "",
    world_outline = "",
    target = "",
    duration = "",
    delivery = "",
    bottle = "",
    euro = "",
    notification = "",
    interval = "",
    teddy = "",
    language = "",
    teach = "",
    dialogue = "",
    play = "",
    pause = "",
    mute = "",
    unmute = "",
    archive = "",
    facebook = "",
    instagram = "",
    pinterest = "",
    twitter = "",
    linkedin = "",
    youtube = "",
    dhl = "",
    dummy_man = "",
    dummy_woman = "",
    quote = "",
    heart_full = "",
    world = "",
    triangle = "",
    phone_full = "",
    podcast = "",
    leaf_outline = "",
    leaf = "",
    lavitastories = "",
    spotify = "",
    itunes = "",
    deezer = "",
    soundcloud = "",
    back = "",
    drop = "",
    navigator = "",
    sl_pause = "",
    nav_magazin = "",
    nav_pp = "",
    zoom = "",
    whatsapp = "",
    pp_honorar = "",
    pp_studien = "",
    pp_geschenk = "",
    lv_usp = "",
    circle_check_filled = "",
    circle_pause_filled = "",
}

export const pictogramOptions = options<typeof Pictograms>(Pictograms)

export const iconOptions = options<typeof Icons>(Icons)
